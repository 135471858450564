import { ClearIcon } from 'components/icons'

interface InputAddressMobileComponentProps {
  isRequired?: boolean
  isDisabled?: boolean
  label?: string
  placeholder?: string
  value?: string
  helperText?: string
  onChange: (v: string) => void
  onMapClick: () => void
  data_testid?: string
}

const InputAddressMobileComponent = (props: InputAddressMobileComponentProps): JSX.Element => {
  const { isRequired, isDisabled, label, placeholder, value, helperText, onChange, onMapClick, data_testid, ...rest } =
    props ?? {}

  return (
    <div className='flex flex-col items-start gap-1.5 self-stretch w-full'>
      <label className='text-sm font-medium leading-5 text-sub-headings '>{label}</label>
      <div className='w-full flex items-center rounded-lg border border-gray-300 bg-card-background01 shadow-xs'>
        <input
          type='text'
          className='w-full flex py-2 pl-3.5  text-gray-400 focus:outline-none'
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          disabled={isDisabled}
          data-testid={data_testid}
          {...rest}
        />

        <div className='flex items-center py-2 px-4 gap-2 text-[#474747] text-base font-normal leading-6'>
          <div className='' style={{ visibility: value ? 'visible' : 'hidden' }} data-testid='DeleteCarpoolIcon'>
            <ClearIcon onClick={() => onChange('')} />
          </div>
          <div
            onClick={onMapClick}
            className='flex items-center justify-center h-[26px] rounded-[300px] bg-[#FFFCEA] p-[3px_4px] text-[#666] text-xs font-medium leading-5 px-2.5'
          >
            Map
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputAddressMobileComponent
