import { Box, Checkbox, FormControlLabel, FormGroup, InputAdornment, Menu, MenuItem } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { CalendarIcon, ClearIcon, FilterIcon, MessagingFilledIcon } from 'components/icons'
import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import React, { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
dayjs.extend(customParseFormat)

const fontSize: string = '0.875rem'

const MenuFilterComponent = ({
  label,
  options,
  onChange,
  testid,
}: {
  label: string
  options: Set<string>
  onChange: (selectedOptions: Set<string>) => void
  testid?: string
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedOptions, setSelectedOptions] = useState<Set<string>>(new Set())

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClear = () => {
    const a: Set<string> = new Set()
    setSelectedOptions(a)
    onChange(a)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target
    const a: Set<string> = new Set(selectedOptions)
    checked ? a.add(name) : a.delete(name)
    setSelectedOptions(a)
    onChange(a)
  }

  const filterCount: number = selectedOptions.size

  return (
    <div data-testid={`${testid}-div`} style={{ fontSize }}>
      <div className='w-[127px] h-[40px] border border-[#E5E5E5] rounded-lg flex items-center justify-between px-2 gap-1 cursor-pointer bg-white'>
        <div className='flex items-center gap-1' onClick={handleClick}>
          <FilterIcon />
          <span>{label}</span>
        </div>
        {filterCount > 0 && (
          <div className='flex items-center gap-1'>
            <div className='bg-custom-yellow rounded-xl px-1'>{`${filterCount}`}</div>
            <ClearIcon onClick={handleClear} />
          </div>
        )}
      </div>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ dense: true }}
        slotProps={{
          paper: {
            sx: {
              maxHeight: 300, // Set max height for the dropdown
              overflowY: 'auto', // Allow scrolling if content overflows
            },
          },
        }}
      >
        <FormGroup>
          {Array.from(options).map((v: string) => (
            <MenuItem key={v} dense>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOptions.has(v)}
                    onChange={handleChange}
                    name={v}
                    size='small'
                    sx={{
                      '&.Mui-checked': {
                        color: '#EBD76F', // Use your custom hex color here
                      },
                      '& .MuiSvgIcon-root': {
                        fill: selectedOptions.has(v) ? '#EBD76F' : '#666666', // Fill color with hex when checked
                      },
                    }}
                  />
                }
                label={<span style={{ fontSize }}>{v}</span>} // Smaller label
              />
            </MenuItem>
          ))}
        </FormGroup>
      </Menu>
    </div>
  )
}

interface FilterComponentProps {
  schedules?: Set<string>
  selectedSchedules?: Set<string>
  activities?: Set<string>
  selectedActivities?: Set<string>
  selectedDate?: string
  onSave: (scheduleSelections?: Set<string>, activitySelections?: Set<string>, dateSelection?: string) => void
}

const FilterComponent = (props: FilterComponentProps) => {
  const navigate: NavigateFunction = useNavigate()

  // eslint-disable-next-line
  const { schedules, activities, onSave } = props
  const [selectedSchedules, setSelectedSchedules] = useState<Set<string> | undefined>(props?.selectedSchedules)
  const [selectedActivities, setSelectedActivities] = useState<Set<string> | undefined>(props?.selectedActivities)
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(
    props?.selectedDate ? dayjs(props.selectedDate) : undefined,
  )

  // hooks
  useEffect(() => {
    setSelectedSchedules(new Set(props?.selectedSchedules))
  }, [props.selectedSchedules])

  useEffect(() => {
    setSelectedActivities(new Set(props?.selectedActivities))
  }, [props.selectedActivities])

  useEffect(() => {
    setSelectedDate(props?.selectedDate ? dayjs(props.selectedDate) : undefined)
  }, [props.selectedDate])

  // handlers
  // eslint-disable-next-line
  const handleScheduleChange = (newSelections: Set<string>) => {
    if (selectedSchedules) {
      setSelectedSchedules(newSelections)
      onSave(newSelections, selectedActivities, selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : undefined)
    }
  }
  const handleActivityChange = (newSelections: Set<string>) => {
    if (selectedActivities) {
      setSelectedActivities(newSelections)
      onSave(selectedSchedules, newSelections, selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : undefined)
    }
  }
  const handleDateChange = (date: Dayjs | null) => {
    const d = date ?? dayjs()
    setSelectedDate(d)
    onSave(selectedSchedules, selectedActivities, d ? dayjs(d).format('YYYY-MM-DD') : undefined)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
        padding: 0,
        margin: 0,
        width: '100%',
      }}
    >
      {/***************AFTERNOON DATEPICKER**********/}
      {selectedDate && (
        <DatePicker
          value={selectedDate}
          formatDensity='dense'
          onChange={handleDateChange}
          format='DD MMM YYYY'
          slotProps={{
            textField: {
              size: 'small',
              variant: 'outlined',
              sx: {
                '& .MuiOutlinedInput-root': {
                  height: '40px', // Set height directly on the input root to ensure consistency
                  borderRadius: '8px', // Apply rounding to the input container
                  '& fieldset': {
                    borderColor: '#E5E5E5', // Apply inline custom yellow color
                  },
                  '&:hover fieldset': {
                    borderColor: '#E5E5E5', // Maintain border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#E5E5E5', // Maintain border color on focus
                    borderWidth: '1px', // FIXME: Purposely disable default thickening of border on focus until we can do the same on other filters
                  },
                },
              },
              InputProps: {
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarIcon />
                  </InputAdornment>
                ),
              },
              inputProps: {
                readOnly: true,
                style: { fontSize: '14px' },
                'data-testid': 'calender-testid', // Set data-testid here
              },
            },
          }}
        />
      )}
      {/***************AFTERNOON ACTIVITIES FILTER**********/}
      {activities && activities.size > 0 && (
        <MenuFilterComponent
          label='Activity'
          options={activities}
          onChange={handleActivityChange}
          testid='activity-filter'
        />
      )}
      {/***************SCHOOL SCHEDULE FILTER**********/}
      {schedules && schedules.size > 1 && (
        <MenuFilterComponent label='Campus' options={schedules} onChange={handleScheduleChange} testid='group-filter' />
      )}

      {/***************NEW GROUP BUTTON**********/}
      <button
        className='flex w-10 h-10 justify-center items-center rounded-lg bg-[#F0E977]'
        aria-label='New Group'
        onClick={() => navigate(`/messaging`)}
        style={{ marginLeft: 'auto' }} // Ensures the button is right-aligned
      >
        <MessagingFilledIcon />
      </button>
    </Box>
  )
}

export default FilterComponent
