import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import InputMobileComponent from 'components/input-mobile'
import NavComponent from 'components/nav'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { formatIncompletePhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import {
  baseURL,
  defaultPhoneNumberCountry,
  getSessionItem,
  passwordTooltip,
  phoneNumberRatesTooltip,
  phoneNumberTooltip,
  reenterPasswordTooltip,
  setSessionItem,
} from 'utils/constants'
import { MessageProps } from 'utils/types'
import { createUser } from 'utils/users'
import { isMatchingPassword, isValidName, isValidPassword, isValidPhone, isValidScheduleGroup } from 'utils/validations'
import './index.css'

function Index(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Schedule Groups, Selected Schedule
  const schoolScheduleGroupNames: string[] = getSessionItem('x-pool-ssgn') || ''
  const selectedScheduleGroup: string = getSessionItem('x-pool-sg') || ''

  // states
  const [userFirstName, setUserFirstName] = useState<string>('')
  const [userLastName, setUserLastName] = useState<string>('')
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('')
  const [userPassword, setUserPassword] = useState<string>('')
  const [userReenteredPassword, setUserReenteredPassword] = useState<string>('')
  const [isConsent, setIsConsent] = useState<boolean>(false)
  const [msg, setMsg] = useState<MessageProps>()
  const formattedPhoneNumber: string = formatIncompletePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry)
  const e164PhoneNumber: string = isValidPhone(userPhoneNumber, true)
    ? parsePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry).format('E.164')
    : ''

  // handlers
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    const { msg, error } = await createUser(e164PhoneNumber, userPassword, `${userFirstName} ${userLastName}`.trim())
    if (error) setMsg(msg)
    else {
      // Save phone number and password for following steps
      setSessionItem('pn', e164PhoneNumber)
      setSessionItem('pwd', userPassword)
      navigate('/signup/verifyUser')
    }
  }

  const isValid: boolean =
    isValidScheduleGroup(schoolScheduleGroupNames, selectedScheduleGroup, true) && // Ensures user created after school verification steps
    isValidName(userFirstName, true) &&
    isValidName(userLastName, true) &&
    isValidPhone(userPhoneNumber, true) &&
    isValidPassword(userPassword, true) &&
    isMatchingPassword(userReenteredPassword, userPassword, true) &&
    isConsent

  return (
    <WizardComponent
      onBack={onBack}
      title={'Create Family Account'}
      disable={!isValid}
      btnText={'Next'}
      onClick={onNext}
      data_testId={'next-btn-testid'}
    >
      <Box display='flex' flexDirection='column' justifyContent='center' paddingTop={2} gap={2}>
        <InputMobileComponent
          type='text'
          labeltext='First Name'
          isRequired={true}
          isError={!isValidName(userFirstName)}
          onChange={(v: string) => setUserFirstName(v)}
        />
        <InputMobileComponent
          type='text'
          labeltext='Last Name'
          isRequired={true}
          isError={!isValidName(userLastName)}
          onChange={(v: string) => setUserLastName(v)}
        />
        <InputMobileComponent
          type='tel'
          labeltext='Phone Number'
          isRequired={true}
          isError={!isValidPhone(userPhoneNumber)}
          helperText={
            !isValidPhone(userPhoneNumber)
              ? phoneNumberTooltip()
              : isValidPhone(userPhoneNumber, true)
                ? phoneNumberRatesTooltip()
                : ''
          }
          onChange={(v: string) => setUserPhoneNumber(v)}
          value={isValidPhone(userPhoneNumber) ? formattedPhoneNumber : userPhoneNumber}
        />
        <InputMobileComponent
          type='Password'
          labeltext='Create Password'
          isRequired={true}
          isError={!isValidPassword(userPassword)}
          helperText={!isValidPassword(userPassword) ? passwordTooltip() : ''}
          onChange={(v: string) => setUserPassword(v)}
        />
        <InputMobileComponent
          type='Password'
          labeltext='Confirm Password'
          isRequired={true}
          isError={!isMatchingPassword(userReenteredPassword, userPassword)}
          helperText={!isMatchingPassword(userReenteredPassword, userPassword) ? reenterPasswordTooltip() : ''}
          onChange={(v: string) => setUserReenteredPassword(v)}
        />
        <Box display='flex' flexDirection='row' justifyContent='center' paddingTop={2}>
          <Checkbox
            size='small'
            color='warning' // Update checkbox state
            checked={isConsent}
            onChange={(v: any) => setIsConsent(v.target.checked)}
          />
          <div className='text-gray-400 text-sm'>
            I agree to the Carpool School Inc.{' '}
            <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
              <NavComponent
                href={`${baseURL}/home/index.html#eula`}
                text='End User License Agreement'
                isOpenNewTab={true}
              />
            </span>
            . Carpool.School does not verify drivers{"'"} licenses, insurance, or conduct background checks. Users are
            responsible for screening all participants.
          </div>
        </Box>
      </Box>

      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </WizardComponent>
  )
}

export default Index
