import Typography from '@mui/material/Typography'
import './index.css'

const TextComponent = (props: any): JSX.Element => {
  const { size, align, children, color, fontWeight, ...sxProps } = props
  return (
    <Typography
      variant={size}
      align={align ?? 'inherit'}
      color={color}
      sx={{ ...sxProps }}
      fontWeight={fontWeight}
      mt={0.1}
    >
      {children}
    </Typography>
  )
}

export default TextComponent
