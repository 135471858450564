import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LinearProgress from '@mui/material/LinearProgress'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import AvatarComponent from 'components/avatar'
import BadgeComponent from 'components/badge'
import TableComponent from 'components/table'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import { parsePhoneNumber } from 'libphonenumber-js/max'
import { useState } from 'react'
import { defaultPhoneNumberCountry, isDateValid, titleCase, titleCaseSchoolNameForUI } from 'utils/constants'
import { deleteStudents, updateStudents } from 'utils/students'
import {
  AddUserProps,
  MessageProps,
  ScheduleGroupProps,
  SchoolUsersStudentProps,
  UpdateStudentProps,
} from 'utils/types'
import { isValidName, isValidPhone, isValidScheduleGroup } from 'utils/validations'
import './index.css'

function Index(props: any): JSX.Element {
  const { schoolUsers, onUpdate } = props ?? {}
  const { schoolName, schoolScheduleGroups, students } = schoolUsers ?? {}
  const [msg, setMsg] = useState<MessageProps>()
  // Constants to store transformed values
  const fileName = `${titleCaseSchoolNameForUI(schoolName)} Family Users`
  // State to store the array of selected user IDs
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  // Function to add user
  const addUser = async (data: AddUserProps, sendSMS?: boolean) => {
    if (students.some((v: SchoolUsersStudentProps) => v.userPhoneNumber === data.userPhoneNumber)) {
      setMsg({
        style: 'error',
        heading: 'Add User Failed - Existing User',
        text: `The user you are trying to add already exists in the system.
        If you have any issues or questions, please contact our support team at support@carpool.school.`,
      })
    } else {
      const s: UpdateStudentProps = { ...data, userRelationship: 'Other' }
      const { msg, error } = await updateStudents([s], sendSMS ? 'SEND' : 'SUPPRESS')
      if (error) setMsg(msg)
      else {
        // Trigger onUpdate callback to update UI
        await onUpdate()
        // If update is successful, display success message and refresh the user table
        setMsg({ style: 'success', text: `User has been added successfully.` })
      }
    }
  }

  // Function to upload selected users
  const uploadUsers = async (data: any) => {
    const erroredRows: number[] = [] // Array to hold validation results
    const students: UpdateStudentProps[] = [] // Array to hold payload
    for (let i = 0; i < data.length; i++) {
      const { Phone, Name, Schedule } = data[i]
      const scheduleGroupName: string =
        schoolScheduleGroups?.length === 1 ? schoolScheduleGroups[0].schoolScheduleGroupName : Schedule
      if (!Phone) continue
      // Validate userPhoneNumber and userName
      if (
        !isValidPhone(Phone, true) ||
        !isValidName(Name, true) ||
        !isValidScheduleGroup(
          schoolScheduleGroups.map((v: ScheduleGroupProps) => v.schoolScheduleGroupName),
          scheduleGroupName,
          true,
        )
      )
        erroredRows.push(i + 2)
      else {
        students.push({
          userPhoneNumber: parsePhoneNumber(Phone, defaultPhoneNumberCountry).format('E.164'),
          userName: Name,
          scheduleGroupName,
          userRelationship: 'Other',
        })
      }
    }
    // If there are validation errors, display them and do not call backend
    if (erroredRows.length > 0) {
      let text: string
      if (erroredRows.length > 7)
        text = `The CSV file you attempted to upload has an invalid entry in a total of ${
          erroredRows.length
        } rows (${erroredRows
          .slice(0, 7)
          .join(
            ', ',
          )} and more). If you continue to experience issues with the CSV file upload, please don't hesitate to contact our support team at support@carpool.school. We'll be happy to assist you further. Thank you for your understanding.`
      else if (erroredRows.length > 1)
        text = `The CSV file you attempted to upload has an invalid entry in the following rows: ${erroredRows.join(
          ', ',
        )}. If you continue to experience issues with the CSV file upload, please don't hesitate to contact our support team at support@carpool.school. We'll be happy to assist you further. Thank you for your understanding.`
      else
        text = `The CSV file you attempted to upload has an invalid entry in row ${erroredRows[0]}. If you continue to experience issues with the CSV file upload, please don't hesitate to contact our support team at support@carpool.school. We'll be happy to assist you further. Thank you for your understanding.`
      setMsg({
        style: 'error',
        heading: 'Upload Failed - Invalid File',
        text,
      })
    } else if (!students?.length) {
      setMsg({
        style: 'error',
        heading: 'Upload Failed - Invalid File',
        text: `The CSV file you attempted to upload seems to be empty or invalid. If you continue to experience issues with the CSV file upload, please don't hesitate to contact our support team at support@carpool.school. We'll be happy to assist you further. Thank you for your understanding.`,
      })
    } else if (students?.length <= 100) {
      // If all rows pass validations,call updateStudents API to add or update users
      const { msg, error } = await updateStudents(students, 'SUPPRESS')
      if (error) {
        // If update is errored, display error message
        setMsg(msg)
      } else {
        // Trigger onUpdate callback to update UI
        await onUpdate()
        // If update is successful, display success message and refresh the user table
        setMsg({
          style: 'success',
          heading: `Upload Complete`,
          text: `The CSV file upload was successful. We have updated the family user accounts with the new information. A total of ${students.length} users have been added or updated in the system. Thank you for using our platform. If you have any further questions or need assistance, please don't hesitate to contact our support team at support@carpool.school.`,
        })
      }
    } else {
      setMsg({
        style: 'error',
        heading: 'Upload Failed - Exceeded Maximum Rows',
        text: `We apologize, but the CSV file you attempted to upload has exceeded the maximum allowed number of rows.

        Our system is currently configured to accept CSV files with up to 100 rows only. Please review your CSV file and ensure it contains no more than 100 data entries. Once you have a file that meets the size requirements, you can try uploading it again.

        If the issue persists, please don't hesitate to contact our support team at support@carpool.school. We'll be happy to assist you further.

        Thank you for your understanding.`,
      })
    }
  }

  // Function to delete selected users
  const deleteUsers = async () => {
    // Call deleteStudents API to delete selected users
    const { msg, error } = await deleteStudents(selectedIds)

    // Handle response from the API
    if (error) {
      setMsg(msg)
    } else {
      // Trigger onUpdate callback to update UI
      await onUpdate()
      // If deletion is successful, set success message
      setMsg({
        style: 'success',
        text: 'Users deleted.',
      })
    }
  }

  // Function to send account link to selected users
  const sendLinkToUsers = async () => {
    // Call updateStudents API to send account activation link to users
    const selectedStudents: UpdateStudentProps[] = students
      .filter((v: SchoolUsersStudentProps) => selectedIds.includes(v.studentId))
      .map((v: SchoolUsersStudentProps) => {
        const { userPhoneNumber, userName, scheduleGroupName } = v
        return { userPhoneNumber, userName, scheduleGroupName }
      })
    const { msg, error } = await updateStudents(selectedStudents, 'RESEND')
    if (error) {
      // If send is errored, display error message
      setMsg(msg)
    } else {
      // Clear selectedIds after successful deletion
      await setSelectedIds([])
      // If send is successful, display success message and refresh the user table
      setMsg({
        style: 'warning',
        text: `Activation link sent to users without existing accounts.`,
      })
    }
  }

  // Function to handle selection change in the data grid
  const handleSelectionChange = (selection: string[]) => {
    setSelectedIds(selection)
  }

  const header: (GridColDef & { visible: boolean })[] = [
    {
      field: 'avatar',
      headerName: 'Avatar',
      type: 'string',
      sortable: false,
      editable: false,
      filterable: false,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: true,
      width: 110,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <AvatarComponent name={params.row.name} photoLink={params.row.photoLink} />
      ),
    },
    {
      field: 'phone',
      headerName: 'Phone',
      type: 'string',
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      visible: false,
      groupable: false,
      disableExport: false,
      width: 150,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <TextComponent size='p'>{params.row.phone}</TextComponent>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      sortable: true,
      editable: false,
      filterable: true,
      hideable: false,
      visible: true,
      groupable: false,
      disableExport: false,
      flex: 1,
      minWidth: 110,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <TextComponent size='p'>{titleCase(params.row.name)}</TextComponent>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'dateTime',
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: false,
      width: 150,
      renderCell: (params: GridRenderCellParams<any, Date>) => {
        return params.row.createdAt ? (
          <BadgeComponent
            text={params.row.createdAt.toJSON().split('T')[0]}
            color={params.row.createdAt ? 'success' : 'primary'}
          />
        ) : (
          <></>
        )
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      type: 'dateTime',
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: false,
      width: 150,
      renderCell: (params: GridRenderCellParams<any, Date>) => {
        return params.row.updatedAt ? (
          <BadgeComponent
            text={params.row.updatedAt.toJSON().split('T')[0]}
            color={params.row.updatedAt ? 'success' : 'primary'}
          />
        ) : (
          <></>
        )
      },
    },
    {
      field: 'isPickup',
      headerName: 'Pickup',
      type: 'boolean',
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: false,
      width: 110,
      renderCell: (params: GridRenderCellParams<any, Date>) => {
        return params.row.isPickup ? <CheckCircleIcon color='success' /> : <></>
      },
    },
    {
      field: 'isDropoff',
      headerName: 'Dropoff',
      type: 'boolean',
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: false,
      width: 110,
      renderCell: (params: GridRenderCellParams<any, Date>) => {
        return params.row.isDropoff ? <CheckCircleIcon color='success' /> : <></>
      },
    },
  ]
  if (schoolScheduleGroups && schoolScheduleGroups?.length > 1) {
    header.splice(3, 0, {
      field: 'schedule',
      headerName: 'Schedule',
      type: 'string',
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: false,
      width: 100,
    })
  }
  const body: GridRowsProp =
    students
      ?.filter((v: SchoolUsersStudentProps) => v.userRelationship !== 'SchoolAdmin')
      .map((v: SchoolUsersStudentProps, i: number) => {
        const {
          userPhoneNumber,
          userName,
          userPhotoLink,
          studentId,
          studentName,
          isPickup,
          isDropoff,
          studentActivities,
          scheduleGroupName,
          createdAt,
          updatedAt,
        } = v
        const phone: string = parsePhoneNumber(userPhoneNumber).formatNational()
        const activities: string = studentActivities && studentActivities.length ? studentActivities.toString() : ''
        return {
          id: studentId,
          phone,
          name: userName ?? '',
          photoLink: userPhotoLink,
          createdAt: createdAt && isDateValid(createdAt) ? new Date(createdAt) : undefined,
          updatedAt: updatedAt && isDateValid(updatedAt) ? new Date(updatedAt) : undefined,
          students: studentName,
          schedule: scheduleGroupName,
          isPickup,
          isDropoff,
          activities,
        }
      }) ?? []

  if (!schoolUsers) return <LinearProgress />

  return (
    <>
      {/***************** Show school name in Title *****************/}
      <TextComponent size='h5' fontWeight='bold' align='center'>{`${titleCaseSchoolNameForUI(
        schoolName,
      )} Families`}</TextComponent>
      {/***************** Display messages in Toast *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** List users in Table *****************/}
      {body && (
        <TableComponent
          isAdmin={true}
          header={header}
          body={body}
          isExpandableRow={false}
          selectedIds={selectedIds}
          onSelectionChange={handleSelectionChange}
          scheduleGroupNames={schoolScheduleGroups.map((v: ScheduleGroupProps) => v.schoolScheduleGroupName)}
          onAddSelected={addUser}
          onUploadSelected={uploadUsers}
          onDeleteSelected={deleteUsers}
          onSendSelected={sendLinkToUsers}
          csvOptions={{ allColumns: true, fileName }}
          printOptions={{ allColumns: true, fileName, hideFooter: true, hideToolbar: true }}
        />
      )}
    </>
  )
}

export default Index
