import { useEffect } from 'react'
import { baseURL } from 'utils/constants'
import './index.css'

function Index(props: any): JSX.Element {
  useEffect(() => {
    window.location.replace(`${baseURL}/home/index.html`)
  }, [])

  return <></>
}

export default Index
