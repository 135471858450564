import CircleLoader from 'components/Circularloader'
import { useState } from 'react'
import ModalConfirmComponent from '../modal-confirm'
import './index.css'

interface ModalButtonComponentProps {
  icon: JSX.Element
  title: string
  body: string
  actionText: string
  disabled?: boolean
  onClick: () => Promise<void>
  data_testid?: string
}

const ModalConfirmRemoveComponent = (props: ModalButtonComponentProps): JSX.Element => {
  const { data_testid, icon, title, body, actionText, disabled = false, onClick } = props ?? {}
  const [show, setShow] = useState<boolean>(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <>
      <button
        onClick={handleShow}
        data-testid={data_testid}
        className={`${disabled ? 'opacity-50 cursor-not-allowed pointer-events-none' : 'cursor-pointer'}`}
      >
        {icon}
      </button>
      <ModalConfirmComponent
        isShow={show}
        title={title}
        body={body}
        actionIcon='redInfoIcon'
        actionText={isLoading ? <CircleLoader /> : actionText}
        actionColor={'bg-custom-red text-white'}
        onActionCancel={handleClose}
        onActionSubmit={async () => {
          setIsLoading(true)
          await onClick()
          handleClose()
          setIsLoading(false)
        }}
      />
    </>
  )
}

export default ModalConfirmRemoveComponent
