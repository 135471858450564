import Paper from '@mui/material/Paper'
import TextComponent from 'components/text'
import './index.css'
import Box from '@mui/material/Box'

const PaperComponent = (props: any): JSX.Element => {
  const { title, subtitle, children, data_testid } = props ?? {}
  return (
    <Paper variant='outlined' data-testid={data_testid} sx={{ p: 1, background: 'transparent', border: 'none' }}>
      {typeof title === 'string' ? (
        <TextComponent align='left' size='h6' fontWeight='bold'>
          {title}
        </TextComponent>
      ) : (
        title
      )}
      {subtitle && (
        // If there is no title, make the subtitle bold
        <TextComponent align='left' fontStyle='italic' size='body2' fontWeight={title ? undefined : 'bold'}>
          {subtitle}
        </TextComponent>
      )}
      <Box sx={{ paddingTop: 1 }}>{children}</Box>
    </Paper>
  )
}

export default PaperComponent
