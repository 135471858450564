import Link from '@mui/material/Link'
import './index.css'

const NavComponent = (props: any): JSX.Element => {
  const { href, text, color, isOpenNewTab } = props
  const newTabProps = isOpenNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {}
  return (
    <Link href={href} color={color ?? 'inherit'} underline='always' {...newTabProps}>
      {text}
    </Link>
  )
}

export default NavComponent
