import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import './index.css'
import MsgComponent from 'components/msg'

const ToastComponent = (props: any): JSX.Element => {
  const { duration, style, heading, text, onClose } = props
  const handleClose = () => typeof onClose === 'function' && onClose()

  const children: JSX.Element = (
    <Snackbar
      open
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      autoHideDuration={duration}
      data-testid='toast-message'
      sx={{
        top: `env(safe-area-inset-top, 0px)`, // Apply safe-area-inset-top for top spacing
        transform: 'translateY(16px)', // Add additional margin below the safe area
      }}
      action={<CloseIcon fontSize='small' color='inherit' onClick={handleClose} />}
    >
      <Alert severity={style} onClose={handleClose}>
        <MsgComponent heading={heading} text={text} />
      </Alert>
    </Snackbar>
  )
  return children
}

export default ToastComponent
