import MetricsComponent from 'components/metrics'
import { SchoolStatProps } from 'utils/types'

type SchoolDashboardProps = {
  schoolStats?: SchoolStatProps
}

function Index(props: SchoolDashboardProps): JSX.Element {
  const { schoolStats } = props
  return <MetricsComponent schoolStats={schoolStats} />
}

export default Index
