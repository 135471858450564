import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useState } from 'react'

interface VerificationCodeInputProps {
  onChange: (value: string) => void
}

const VerificationCodeInput: React.FC<VerificationCodeInputProps> = ({ onChange }) => {
  const [values, setValues] = useState<string[]>(['', '', '', '', '', ''])

  const handleChange = (index: number, value: string) => {
    if (!/^\d?$/.test(value)) return // Only allow digits
    const newValues = [...values]
    newValues[index] = value
    setValues(newValues)
    onChange(newValues.join(''))
    if (value && index < 5) {
      const nextInput = document.getElementById(`code-input-${index + 1}`)
      if (nextInput) nextInput.focus()
    }
  }

  const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
    if (e.key === 'Backspace' && !values[index] && index > 0) {
      const prevInput = document.getElementById(`code-input-${index - 1}`)
      if (prevInput) prevInput.focus()
    }
  }

  const theme = useTheme()
  const isUpMD: boolean = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box display='flex' justifyContent='center' sx={{ marginTop: '10px' }} gap={1}>
      {isUpMD
        ? values.map((value, index) => (
            <TextField
              type='number'
              key={index}
              id={`code-input-${index}`}
              value={value}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              inputProps={{
                maxLength: 1,
                inputMode: 'numeric',
                // This CSS removes the spinner controls in most browsers
                style: { textAlign: 'center', fontSize: '1.3rem', MozAppearance: 'textfield' },
              }}
              sx={{
                width: '3rem',
                borderRadius: '4px',
                margin: '5px',
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none', // Hide spin buttons in WebKit browsers (Chrome, Safari)
                },
                '& input[type=number]': {
                  MozAppearance: 'textfield', // Hide spin buttons in Firefox
                },
              }}
            />
          ))
        : values.map((value, index) => (
            <TextField
              type='number'
              key={index}
              id={`code-input-${index}`}
              value={value}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              inputProps={{
                maxLength: 1,
                inputMode: 'numeric',
                style: { textAlign: 'center', fontSize: '1.2rem', fontWeight: '600', MozAppearance: 'textfield' },
              }}
              sx={{
                width: '3.3rem',
                borderRadius: '4px',
                margin: '0px',
                backgroundColor: 'rgba(224, 224, 224,0.5)',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                },
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none', // Hide spin buttons in WebKit browsers (Chrome, Safari)
                },
                '& input[type=number]': {
                  MozAppearance: 'textfield', // Hide spin buttons in Firefox
                },
              }}
            />
          ))}
    </Box>
  )
}

export default VerificationCodeInput
