import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import './index.css'

const SwitchComponent = (props: any): JSX.Element => {
  const { disable, isChecked, children, onChange, color, placement } = props ?? {}
  return (
    <FormControlLabel
      control={
        <Switch disabled={disable ? true : false} checked={isChecked} onChange={onChange} color={color ?? 'primary'} />
      }
      label={
        <Typography variant='h6' component='span'>
          {children ?? ''}
        </Typography>
      }
      labelPlacement={placement ?? 'end'}
    />
  )
}

export default SwitchComponent
