import LinearProgress from '@mui/material/LinearProgress'
import {
  ArrowIcon,
  ChangePasswordIcon,
  ChangeSchoolIcon,
  DeleteAccountIcon,
  LogOutIcon,
  MessagingIcon,
  SettingsIcon,
  TermsAndConditionsIcon,
} from 'components/icons'
import ModalConfirmComponent from 'components/modal-confirm'
import ToastComponent from 'components/toast'
import React, { useEffect, useRef, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { baseURL, setItem } from 'utils/constants'
import { MessageProps } from 'utils/types'
import { deleteUser } from 'utils/users'
import navIcon from '../../images/nav.svg'
import ButtonMenuComponent from '../button-menu'
import MenuProfileMobileComponent from '../menu-profile-mobile'

interface MenuProps {
  isOpen: boolean
  phoneNumber: string
  name: string
  studentCount: number
  relationship?: string
  photoLink?: string
  onOpen: () => void // New prop for handling panel open
  onClose: () => void // New prop for handling panel closure
}

const Menu: React.FC<MenuProps> = (props: MenuProps) => {
  const navigate: NavigateFunction = useNavigate()

  // INPUTS: Name, Relationship
  const { isOpen, phoneNumber, name, studentCount, relationship, photoLink, onOpen, onClose } = props

  // states
  const panelRef = useRef<HTMLDivElement>(null)
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false)
  const [delOpen, setDelOpen] = useState<boolean>(false)
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // handlers
  const handleToggleSettings = () => setIsSettingsOpen(!isSettingsOpen)
  const handleDelClick = () => setDelOpen(true)
  const handleDelClose = () => setDelOpen(false)
  const handleDelSubmit = async () => {
    setDelOpen(false)
    setIsLoading('Deleting Family Users Account')
    const { msg, error } = await deleteUser(phoneNumber)
    setIsLoading('')
    setItem('tokens', null) // clear session even if API resturned an error response- backend may have purged user
    if (error) setMsg(msg)
    else navigate('/')
  }

  //hooks
  useEffect(() => {
    const handleOutsideInteraction = (event: MouseEvent | TouchEvent) => {
      const target = event.target as Node
      if (panelRef.current && !panelRef.current.contains(target) && !delOpen) {
        onClose()
      }
    }
    document.addEventListener('mousedown', handleOutsideInteraction)
    document.addEventListener('touchstart', handleOutsideInteraction)
    return () => {
      document.removeEventListener('mousedown', handleOutsideInteraction)
      document.removeEventListener('touchstart', handleOutsideInteraction)
    }
  }, [onClose, delOpen])

  return (
    <>
      <button
        onClick={onOpen}
        data-testid='nav-icon-testid'
        style={{
          position: 'fixed',
          top: '16px',
          left: '16px',
          zIndex: 0,
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        <img src={navIcon} alt='' />
      </button>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '60%',
          transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
          transition: 'transform 0.3s ease-in-out',
          zIndex: 4, // Ensure side panel is above the overlay
        }}
      >
        {/***************** Display progress and error *****************/}
        {isLoading && <LinearProgress />}
        {msg && (
          <ToastComponent
            style={msg?.style}
            heading={msg?.heading}
            text={msg?.text}
            onClose={() => setMsg(undefined)}
          />
        )}
        {/***************** Menu *****************/}
        <div ref={panelRef} className='flex flex-col items-start gap-4 h-screen py-10 pb-6 overflow-y-auto bg-white'>
          <MenuProfileMobileComponent name={name} photoLink={photoLink} relationship={relationship} href='/profile' />
          <div className='h-0.5 w-[90%] bg-custom-gray mx-auto'></div>
          <div className='flex flex-col items-start flex-1 self-stretch '>
            <ButtonMenuComponent
              name='Messaging (Beta)'
              leftIcon={<MessagingIcon />}
              onClick={() => navigate('/messaging')}
              data_testid={'messaging-testid'}
            />
            <ButtonMenuComponent
              name='Change School'
              leftIcon={<ChangeSchoolIcon />}
              onClick={() => navigate('/change')}
              data_testid={'change-school-testid'}
            />
            <ButtonMenuComponent
              name='Settings'
              style={{
                backgroundColor: '#FFFDF6',
              }}
              leftIcon={<SettingsIcon />}
              rightIcon={<ArrowIcon isOpen={isSettingsOpen} />}
              onClick={handleToggleSettings}
            />

            <div
              className={`w-full pl-6  bg-warm-white overflow-hidden transition-all duration-300 ease-in-out ${
                isSettingsOpen ? 'max-h-60' : 'max-h-0'
              }`}
            >
              {/* <ButtonMenuComponent name='Subscription' leftIcon={<SubscriptionIcon />} /> */}
              <ButtonMenuComponent
                name='Change Password'
                leftIcon={<ChangePasswordIcon />}
                onClick={() => navigate('/settings')}
              />
              {/* <ButtonMenuComponent name='Notification' leftIcon={<NotificationIcon />} /> */}
              <ButtonMenuComponent
                name='Terms & Conditions'
                onClick={() => navigate(`${baseURL}/home/index.html#legal`)}
                leftIcon={<TermsAndConditionsIcon />}
              />
              <ButtonMenuComponent name='Delete Account' leftIcon={<DeleteAccountIcon />} onClick={handleDelClick} />
            </div>
          </div>
          <div className='ml-1'>
            <ButtonMenuComponent
              name='Sign out'
              leftIcon={<LogOutIcon />}
              onClick={() => navigate('/signout')}
              data_testid={'logout-testid'}
            />
          </div>
        </div>
        {/***************** Delete Modal *****************/}
        <ModalConfirmComponent
          isShow={delOpen}
          title={studentCount ? 'Account Deletion Blocked' : 'Delete Account?'}
          body={
            studentCount
              ? "You can't delete your account while registered with schools. Please remove school affiliations to delete your account. For help, contact support@carpool.school."
              : 'You will lose access to this account. This action cannot be undone.'
          }
          actionIcon={studentCount ? 'redBlockIcon' : 'redInfoIcon'}
          onClose={studentCount ? handleDelClose : undefined}
          onActionCancel={studentCount ? undefined : handleDelClose}
          onActionSubmit={studentCount ? undefined : handleDelSubmit}
        />
      </div>
    </>
  )
}

export default Menu
