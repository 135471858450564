import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import './index.css'

const InputComponent = (props: any): JSX.Element => {
  const {
    isRequired,
    isDisabled,
    type,
    label,
    tooltip,
    defaultValue,
    value,
    onChange,
    isError,
    isSuccess,
    helperText,
    data_testid,
    placeholder,
    width,
    rows,
  } = props ?? {}
  const children: JSX.Element = (
    <center>
      <TextField
        required={isRequired}
        disabled={isDisabled}
        type={type}
        label={label}
        defaultValue={defaultValue}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        error={isError}
        helperText={helperText}
        fullWidth={true}
        color={isError ? 'error' : isSuccess ? 'success' : 'primary'}
        sx={{
          textAlign: 'left',
          borderRadius: '30px',
          width: width ?? '100%',
          '& .MuiOutlinedInput-root': { borderRadius: '30px' },
        }}
        margin='dense'
        size='small'
        data-testid={data_testid}
        placeholder={placeholder}
        multiline={rows !== undefined} // Set multiline based on rows prop
        rows={rows} // Configure rows if defined
      ></TextField>
    </center>
  )
  return tooltip ? (
    <Tooltip title={tooltip} followCursor disableHoverListener>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  )
}

export default InputComponent
