import { isPossiblePhoneNumber } from 'libphonenumber-js'
import { defaultPhoneNumberCountry } from './constants'
import { enabledRelationships } from './students'

export const isValidSchool = (name: string | undefined): boolean => {
  if (name) return true
  else return false
}

export const isValidEmail = (email: string | undefined): boolean => {
  if (email && email.includes('@')) return true
  else return false
}

export const isValidUrl = (url: string | undefined, isStrict?: boolean): boolean => {
  if (!isStrict && !url) return true
  else if (url && url.startsWith('https://') && url.includes('.')) return true
  else return false
}

export const isValidCode = (code: string | undefined): boolean => {
  const regex = /^[0-9]{6}$/
  if (code && regex.test(code)) return true
  else return false
}

export const isValidSchoolCode = (schoolCode: string | undefined, isStrict?: boolean): boolean => {
  const regex = /\S/
  if (!isStrict && !schoolCode) return true
  else if (schoolCode && regex.test(schoolCode)) return true
  else return false
}

export const isValidName = (name?: string, isStrict?: boolean): boolean => {
  if (!isStrict && !name) return true
  else if (name) return true
  else return false
}

export const isValidPhone = (phoneNumber?: string, isStrict?: boolean): boolean => {
  if (!isStrict && !phoneNumber) return true
  else if (phoneNumber && isPossiblePhoneNumber(phoneNumber, defaultPhoneNumberCountry)) return true
  else return false
}

export const isValidPassword = (password?: string, isStrict?: boolean): boolean => {
  const regex = /^.{8,256}$/
  if (!isStrict && !password) return true
  else if (password && regex.test(password)) return true
  else return false
}

export const isMatchingPassword = (password?: string, str?: string, isStrict?: boolean): boolean => {
  if (!isValidPassword(str, true)) return true
  else if (!isStrict && !password) return true
  else if (password === str) return true
  else return false
}

export const isValidRelationship = (relationship?: string, isStrict?: boolean): boolean => {
  if (!isStrict && !relationship) return true
  else if (relationship && enabledRelationships.includes(relationship)) return true
  else return false
}

export const isValidScheduleGroup = (scheduleGroups: string[], scheduleGroup?: string, isStrict?: boolean): boolean => {
  if (!isStrict && !scheduleGroup) return true
  else if (scheduleGroup && scheduleGroups.some((v: string) => v === scheduleGroup)) return true
  else return false
}

export const isValidComments = (comments?: string, isStrict?: boolean): boolean => {
  if (!isStrict && !comments) return true
  else if (comments && comments.length <= 500) return true
  else return false
}

export const isValidGroupName = (groupName: string, isStrict?: boolean): boolean => {
  if (!isStrict && !groupName) return true
  else return /^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{}:,.?~-]{1,20}$/.test(groupName)
}

export const isValidGroupDescription = (groupDescription: string, isStrict?: boolean): boolean => {
  if (!isStrict && !groupDescription) return true
  else if (groupDescription && groupDescription.length <= 200) return true
  else return false
}
