import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import './index.css'
import Paper from '@mui/material/Paper'
import CloseButtonComponent from 'components/close'
import BackComponent from 'components/back'

const CenterComponent = (props: any): JSX.Element => {
  const { children, spacing, onClose, onBack } = props
  const width = props?.width ?? '100%'
  const marginTop = props?.marginTop ?? '50px'

  // onClose of undefined means we do not need logo and back button
  // onClose of null is used as a proxy to show logo but without CTA
  // if logo is shown, onBack is used to optionally show the back button
  const element: JSX.Element =
    onClose !== undefined ? (
      <Paper sx={{ px: 1, pb: 3, border: 'none', background: 'transparent', boxShadow: 'none', width }}>
        {/* Only show back button if CTA */}
        {onBack && <BackComponent onClick={onBack} />}
        {/* Always show logo */}
        <CloseButtonComponent onClick={onClose} />
        <Stack spacing={spacing ?? 3}>{children}</Stack>
      </Paper>
    ) : (
      children
    )
  return (
    <Grid container spacing={0} alignItems='center' justifyContent='center' textAlign='center' sx={{ marginTop }}>
      <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
      <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
        <Stack spacing={spacing ?? 3}>{element}</Stack>
      </Grid>
      <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
    </Grid>
  )
}

export default CenterComponent
