/* eslint @typescript-eslint/no-unused-vars: "off" */
import LightModeIcon from '@mui/icons-material/LightMode'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import PeopleIcon from '@mui/icons-material/People'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import { PieValueType } from '@mui/x-charts'
import PieChartComponent from 'components/piechart'
import PieChartMobileComponent from 'components/piechart-mobile'
import ValueChartComponent from 'components/valuechart'
import { SchoolStatProps } from 'utils/types'
import { isWeb } from 'utils/constants'
import './index.css'

type MetricsComponentProps = {
  schoolStats?: SchoolStatProps
  isHidePickup?: boolean
  isHideDropoff?: boolean
}

const MetricsComponent = (props: MetricsComponentProps) => {
  const { schoolStats, isHidePickup, isHideDropoff } = props ?? {}
  const { totalUsers, pickupUsers, dropoffUsers, inactiveUsers } = schoolStats ?? {}
  const getPieValues = (o?: Record<string, number>): PieValueType[] => {
    return o
      ? Object.keys(o ?? {})
          .map((v: any, id: number) => ({ id, label: v, value: o[v] }))
          .sort((a: any, b: any) => b.value - a.value)
      : []
  }
  const pickupUsersByCity: PieValueType[] = getPieValues(schoolStats?.pickupUsersByCity)
  const dropoffUsersByCity: PieValueType[] = getPieValues(schoolStats?.dropoffUsersByCity)
  const pickupUsersByPostalCode: PieValueType[] = getPieValues(schoolStats?.pickupUsersByPostalCode)
  const dropoffUsersByPostalCode: PieValueType[] = getPieValues(schoolStats?.dropoffUsersByPostalCode)

  const valueElements = (
    <Grid container spacing={0}>
      {!isHidePickup && !isHideDropoff && (
        <ValueChartComponent
          title='Total Users'
          data_testid='total-users-testid'
          value={totalUsers}
          avatar={
            <Avatar sx={{ backgroundColor: '#c0ba5f' }}>
              <PeopleIcon />
            </Avatar>
          }
        />
      )}
      {!isHidePickup && !isHideDropoff && (
        <ValueChartComponent
          title='Inactive Users'
          value={inactiveUsers}
          avatar={
            <Avatar sx={{ backgroundColor: '#c0ba5f' }}>
              <PeopleIcon />
            </Avatar>
          }
        />
      )}
      {!isHidePickup && (
        <ValueChartComponent
          title='Morning Users'
          value={pickupUsers}
          avatar={
            <Avatar sx={{ backgroundColor: '#c0ba5f' }}>
              <LightModeOutlinedIcon />
            </Avatar>
          }
        />
      )}
      {!isHideDropoff && (
        <ValueChartComponent
          title='Afternoon Users'
          value={dropoffUsers}
          avatar={
            <Avatar sx={{ backgroundColor: '#c0ba5f' }}>
              <LightModeIcon />
            </Avatar>
          }
        />
      )}
    </Grid>
  )

  const pieChartElements: JSX.Element = (
    <Grid container spacing={0}>
      {pickupUsersByCity.length > 0 && !isHidePickup && (
        <PieChartComponent
          title={
            <div className='mb-2.5'>
              <LightModeOutlinedIcon sx={{ fontSize: 20 }} />
              &nbsp;&nbsp; Morning Users by City
            </div>
          }
          data={pickupUsersByCity}
          height={300}
        />
      )}
      {pickupUsersByPostalCode.length > 0 && !isHidePickup && (
        <PieChartComponent
          title={
            <div className='mb-2.5'>
              <LightModeOutlinedIcon sx={{ fontSize: 20 }} />
              &nbsp;&nbsp; Morning Users by ZipCode
            </div>
          }
          data={pickupUsersByPostalCode}
          height={300}
        />
      )}
      {dropoffUsersByCity.length > 0 && !isHideDropoff && (
        <PieChartComponent
          title={
            <div className='mb-2.5'>
              <LightModeIcon sx={{ fontSize: 20 }} />
              &nbsp;&nbsp; Afternoon Users by City
            </div>
          }
          data={dropoffUsersByCity}
          height={300}
        />
      )}
      {dropoffUsersByPostalCode.length > 0 && !isHideDropoff && (
        <PieChartComponent
          title={
            <div className='mb-2.5'>
              <LightModeIcon sx={{ fontSize: 20 }} />
              &nbsp;&nbsp; Afternoon Users by ZipCode
            </div>
          }
          data={dropoffUsersByPostalCode}
          height={300}
        />
      )}
    </Grid>
  )

  const pieChartMobileElements: JSX.Element = (
    <Grid container spacing={0}>
      {pickupUsersByCity.length > 0 && !isHidePickup && (
        <PieChartMobileComponent
          title={
            <div className=''>
              <LightModeOutlinedIcon sx={{ fontSize: 20 }} />
              &nbsp;&nbsp; Morning Users by City
            </div>
          }
          data={pickupUsersByCity}
          height={420}
        />
      )}
      {pickupUsersByPostalCode.length > 0 && !isHidePickup && (
        <PieChartMobileComponent
          title={
            <div className=''>
              <LightModeOutlinedIcon sx={{ fontSize: 20 }} />
              &nbsp;&nbsp; Morning Users by ZipCode
            </div>
          }
          data={pickupUsersByPostalCode}
          height={450}
        />
      )}
      {dropoffUsersByCity.length > 0 && !isHideDropoff && (
        <PieChartMobileComponent
          title={
            <div className=''>
              <LightModeIcon sx={{ fontSize: 20 }} />
              &nbsp;&nbsp; Afternoon Users by City
            </div>
          }
          data={dropoffUsersByCity}
          height={420}
        />
      )}
      {dropoffUsersByPostalCode.length > 0 && !isHideDropoff && (
        <PieChartMobileComponent
          title={
            <div className=''>
              <LightModeIcon sx={{ fontSize: 20 }} />
              &nbsp;&nbsp; Afternoon Users by ZipCode
            </div>
          }
          data={dropoffUsersByPostalCode}
          height={450}
        />
      )}
    </Grid>
  )

  return (
    <>
      {valueElements}
      {isWeb ? pieChartElements : pieChartMobileElements}
    </>
  )
}

export default MetricsComponent
