import { IconButton, LinearProgress } from '@mui/material'
import AvatarComponent from 'components/avatar'
import {
  BackIcon,
  DeleteGroupIcon,
  ExitGroupIcon,
  GroupInfoIcon,
  PrivateGroupIcon,
  PublicGroupIcon,
} from 'components/icons'
import ModalConfirmRemoveComponent from 'components/modal-confirm-remove'
import { TopAppBarComponent } from 'components/navbar'
import RoomComponent from 'components/room'
import RoomAddUsersComponent from 'components/room-add-users'
import RoomCreateComponent from 'components/room-create'
import RoomInfoComponent from 'components/room-info'
import RoomJoinComponent from 'components/room-join'
import RoomsComponent from 'components/rooms'
import ToastComponent from 'components/toast'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { RoomType, RoomUsersOutputProps, UserRoomsOutputProps } from 'types/RoomTypes'
import { UsersOutputProps } from 'types/UserTypes'
import { MIN_ROOM_ADMINS, parseJwt } from 'utils/constants'
import { addRoomUsers, deleteRoom, getRooms, getRoomUsers, removeRoomUsers, updateRoom } from 'utils/rooms'
import { MessageProps, StudentProps } from 'utils/types'
import { getUsers } from 'utils/users'

interface Props {
  tokens: any
  students: StudentProps[]
  onUpdate: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigate: NavigateFunction = useNavigate()

  // Check if /messaging has roomId is in path parameter first, if not assume /messaging?
  const roomId: string = params?.roomId ?? searchParams.get('roomId') ?? ''
  const action = params?.roomId ? '' : (searchParams.get('action') ?? '')
  const comId = params?.roomId ? '' : (searchParams.get('comId') ?? '')

  // INPUTS
  const { tokens, students, onUpdate } = props
  const { IdToken } = tokens
  const userId: string = parseJwt(IdToken).phone_number

  // Get communities enrolled by current user
  const communityIds: string[] = students.map((v) => v.schoolName)
  const communities: Record<string, { studentId: string; isNeedPMPool: boolean }> = students.reduce(
    (acc: Record<string, { studentId: string; isNeedPMPool: boolean }>, student: StudentProps) => {
      const { schoolName, studentId } = student
      const pmLocation = student?.dropoffLocation ?? { address: '' }
      const isNeedPMPool = pmLocation && pmLocation?.lat && pmLocation?.lng ? true : false
      acc[schoolName] = { studentId, isNeedPMPool }
      return acc
    },
    {},
  )

  // STATES
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const [rooms, setRooms] = useState<UserRoomsOutputProps[]>([])
  const [users, setUsers] = useState<UsersOutputProps[]>([])
  const [room, setRoom] = useState<UserRoomsOutputProps | null>(null)
  const [roomUsers, setRoomUsers] = useState<RoomUsersOutputProps[]>([])
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // HANDLERS
  const handleUpdateRoomAdmin = async (id: string, admins: string[]) => {
    setIsLoading('Updating room admins...')
    const { msg, error } = await updateRoom(id, undefined, undefined, undefined, admins)
    if (error) setMsg(msg)
    else setRefreshKey((prev) => prev + 1)
    setIsLoading('')
  }
  const handleAddRoomUser = async (id: string, users: string[]) => {
    setIsLoading('Adding users to room...')
    const { msg, error } = await addRoomUsers(id, users)
    if (error) setMsg(msg)
    else navigate(`/messaging/${roomId}`)
    setIsLoading('')
  }
  const handleRemoveRoomUser = async (id: string, users: string[]) => {
    setIsLoading('Removing users from room...')
    const { msg, error } = await removeRoomUsers(id, users)
    if (error) setMsg(msg)
    else {
      setMsg({ style: 'success', text: `Member removed.`, duration: 3000 })
      setRefreshKey((prev) => prev + 1)
    }
    setIsLoading('')
  }
  const handleDeleteRoom = async (id: string) => {
    setIsLoading('Deleting room...')
    const { msg, error } = await deleteRoom(id)
    if (error) setMsg(msg)
    else navigate('/messaging')
    setIsLoading('')
  }

  // HOOKS
  useEffect(() => {
    (async function () {
      setIsLoading('Loading rooms and users...')
      const [res1, res2] = await Promise.all([getRooms(), getUsers()])
      if (res1.error || res2.error) setMsg(res1.msg)
      else if (res1.data && res2.data) {
        const r = res1.data.rooms as UserRoomsOutputProps[]
        const u = res2.data.users as UsersOutputProps[]
        setRooms(r)
        setUsers(u)
        if (roomId) {
          const sr = r.find((v) => v.roomId === roomId && v.userIsMember)
          if (sr) {
            setRoom(sr)
            const res = await getRoomUsers(roomId)
            if (res.error) setMsg(res.msg)
            else if (res.data) {
              const ru = res.data.users as RoomUsersOutputProps[]
              setRoomUsers(ru)
            }
          } else navigate('/') // Go to dashboard if room not found- do NOT go back to messaging page, creates recurive loop & back button breaks
        } else {
          setRoom(null)
          setRoomUsers([])
        }
      }
      setIsLoading('')
    })()
  }, [refreshKey, action, roomId, navigate])

  // REUSABLE JSX ELEMENTS
  // Needs all rooms as seen by the user to view, and communities just for filtering
  const roomsElement: JSX.Element = (
    <RoomsComponent rooms={rooms} isShowCommunityName={communityIds?.length > 1 ? true : false} />
  )
  // Needs the room and the user
  const roomElement: JSX.Element = room ? (
    <TopAppBarComponent
      appBarColor='#ffffff'
      backgroundColor='#f9f9f9'
      iconElement={
        <div className='cursor-pointer' onClick={() => navigate(-1)} data-testid='back-fixed-button-testid'>
          <BackIcon />
        </div>
      }
      leftElement={
        <div
          className='flex items-center gap-2 cursor-pointer'
          onClick={() => {
            if (
              room.roomType === RoomType.PRIVATE &&
              room.roomCommunityId &&
              Array.isArray(room.roomMembers) &&
              room.roomMembers.length === 2
            ) {
              const otherMemberPhoneNumber = room.roomMembers?.find((v) => v !== userId)
              navigate(`/userpool/${room.roomCommunityId}`, { state: { userPhoneNumber: otherMemberPhoneNumber } })
            } else navigate(`/messaging?action=roomInfo&roomId=${roomId}`)
          }}
        >
          {room.roomType === RoomType.PRIVATE && (
            <AvatarComponent name={room.roomName} photoLink={room?.roomPhotoLink} />
          )}
          {room.roomType === RoomType.GROUP && <PrivateGroupIcon width={40} height={40} />}
          {(room.roomType === RoomType.PUBLIC || room.roomType === RoomType.ACTIVITY) && (
            <PublicGroupIcon width={40} height={40} />
          )}
          <span className='text-[color:var(--Color-Tokens-Text-Modes-Base-Second,#68727D)] text-xl font-semibold'>
            {room.roomName}
          </span>
        </div>
      }
      rightElement={
        room.roomType === RoomType.PRIVATE ? (
          <ModalConfirmRemoveComponent
            icon={<DeleteGroupIcon />}
            title='Delete Group'
            body={`All messages will be permanently deleted. Are you sure?`}
            actionText='Delete now'
            onClick={() => handleDeleteRoom(roomId)}
          />
        ) : (
          <IconButton onClick={() => navigate(`/messaging?action=roomInfo&roomId=${roomId}`)}>
            <GroupInfoIcon />
          </IconButton>
        )
      }
    >
      <RoomComponent room={room} roomUsers={roomUsers} userId={userId} />
    </TopAppBarComponent>
  ) : (
    <></>
  )
  // Needs the room and the user
  const roomInfoElement = room ? (
    <RoomInfoComponent
      room={room}
      roomUsers={roomUsers}
      userId={userId}
      onUpdateRoomAdmins={handleUpdateRoomAdmin}
      onRemoveRoomUsers={handleRemoveRoomUser}
      exitDeleteModals={
        <div className='flex gap-4'>
          <ModalConfirmRemoveComponent
            icon={<ExitGroupIcon />}
            title='Exit Group'
            body={`Are you sure you want to leave this group?`}
            actionText='Exit now'
            disabled={room.roomAdmins?.includes(userId) && room.roomAdmins?.length === MIN_ROOM_ADMINS}
            onClick={() => handleRemoveRoomUser(roomId, [userId])}
          />
          {room.roomAdmins?.includes(userId) && (
            <ModalConfirmRemoveComponent
              icon={<DeleteGroupIcon />}
              title='Delete Group'
              body={`All messages will be permanently deleted. Are you sure?`}
              actionText='Delete now'
              onClick={() => handleDeleteRoom(roomId)}
            />
          )}
        </div>
      }
    />
  ) : (
    <></>
  )
  // Needs all users as seen by the user to create room, private rooms to reuse, communities used to filter, onUpdate just to be safe & for consistency
  const createRoomElement: JSX.Element = (
    <RoomCreateComponent
      users={users}
      privateRooms={rooms.filter((v) => v.roomType === RoomType.PRIVATE)}
      communityIds={communityIds}
      defaultCommunityId={comId}
      userId={userId}
      onUpdate={onUpdate}
    />
  )
  // Needs all rooms as seen by the user to join, communities used to validate user has PM carpool before joining, onUpdate to sync updated student activities
  const joinRoomElement: JSX.Element = (
    <RoomJoinComponent rooms={rooms} communities={communities} userId={userId} onAddRoomUsers={handleAddRoomUser} />
  )
  // Needs all users as seen by the user to add to a room, room to add, communities used to filter & validate, onUpdate just to be safe & for consistency
  const addUserElement: JSX.Element = room ? (
    <RoomAddUsersComponent
      users={users}
      room={room}
      roomUsers={roomUsers}
      userId={userId}
      onAddRoomUsers={handleAddRoomUser}
    />
  ) : (
    <></>
  )

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent
          style={msg?.style}
          heading={msg?.heading}
          text={msg?.text}
          duration={msg?.duration}
          onClose={() => setMsg(undefined)}
        />
      )}
      {!action && !roomId && roomsElement}
      {!action && roomId && roomElement}
      {action === 'roomInfo' && roomInfoElement}
      {action === 'joinRoom' && joinRoomElement}
      {action === 'createRoom' && createRoomElement}
      {action === 'roomAdd' && addUserElement}
    </>
  )
}

export default Index
