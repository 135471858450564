import { UpdateUserInputProps, UserOutputProps, UserPoolOutputProps } from 'types/UserTypes'
import { http, httpBasicAuth, httpBearerAuth, processError } from 'utils/requests'
import { ResponseProps } from 'utils/types'

export const createUser = async (phoneNumber: string, password: string, name: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/users`, '', { phoneNumber, password, name })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}
export const resendCode = async (phoneNumber: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/resendCode`, '', { phoneNumber })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}
export const verifyUser = async (phoneNumber: string, code: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/verifyUser`, '', { phoneNumber, code })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const signInUser = async (phoneNumber: string, password: string): Promise<ResponseProps> => {
  try {
    await httpBasicAuth('post', `/api/signInUser`, { username: phoneNumber, password })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const signOutUser = async (): Promise<ResponseProps> => {
  try {
    await httpBearerAuth('post', `/api/signOutUser`)
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateUserPassword = async (
  phoneNumber: string,
  accessToken: string,
  password: string,
  newPassword: string,
): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('post', `/api/updateUserPassword`, '', {
      phoneNumber,
      accessToken,
      password,
      newPassword,
    })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const forgotUserPassword = async (phoneNumber: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/forgotUserPassword`, '', { phoneNumber })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const setUserPassword = async (
  phoneNumber: string,
  tempPassword: string,
  password: string,
): Promise<ResponseProps> => {
  try {
    await http('post', `/api/setUserPassword`, '', { phoneNumber, tempPassword, password })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const resetUserPassword = async (
  phoneNumber: string,
  code: string,
  password: string,
): Promise<ResponseProps> => {
  try {
    await http('post', `/api/resetUserPassword`, '', { phoneNumber, code, password })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateUser = async (
  phoneNumber: string,
  userFirstName?: string,
  userLastName?: string,
  userBio?: string,
  userSocialLinks?: Record<string, string>,
): Promise<ResponseProps> => {
  try {
    const body: UpdateUserInputProps = { userFirstName, userLastName, userBio, userSocialLinks }
    const { data } = await httpBearerAuth('patch', `/api/users/${phoneNumber}`, '', body as Record<string, unknown>)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const deleteUser = async (phoneNumber: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('delete', `/api/users/${phoneNumber}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getUser = async (
  phoneNumber: string,
): Promise<ResponseProps<{ message: string; user: UserOutputProps }>> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/users/${phoneNumber}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getUserPool = async (
  userId: string,
  comId: string,
  userStudentId?: string,
  studentId?: string,
): Promise<ResponseProps<{ message: string; userPool: UserPoolOutputProps }>> => {
  try {
    // student ids are only relevant for the really edge case when a user has more than one students in same community as separate registrations
    const { data } = await httpBearerAuth(
      'get',
      `/api/users/${userId}/pool/${comId.toLowerCase()}?userStudentId=${userStudentId}&studentId=${studentId}`,
    )
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getUsers = async (): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/users`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const uploadUserPhoto = async (userId: string, formData: FormData): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('put', `/api/users/${userId}/photo`, '', formData)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const deleteUserPhoto = async (userId: string): Promise<ResponseProps> => {
  try {
    await httpBearerAuth('delete', `/api/users/${userId}/photo`)
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getUserPhoto = async (userId: string): Promise<ResponseProps<{ message: string; userPhoto: string }>> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/users/${userId}/photo`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}
