import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { decode } from 'base64-arraybuffer'

export const getCameraPhoto = async (source: CameraSource): Promise<FormData | undefined> => {
  try {
    const { base64String, format } = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source,
      quality: 30,
    })
    if (!base64String) throw new Error('Invalid photo')
    const blob = new Blob([new Uint8Array(decode(base64String))], {
      type: `image/${format}`,
    })
    const formData = new FormData()
    formData.append('file', blob)
    return formData
  } catch (error) {
    return
  }
}
