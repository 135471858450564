import { CheckIcon, EditFilledIcon } from 'components/icons'
import React, { useCallback, useEffect, useRef, useState } from 'react'

interface InlineEditInputComponentProps {
  defaultValue?: string
  isEditable?: boolean
  validate?: (text: string) => boolean
  onSave: (newValue: string) => void
}

const InlineEditInputComponent: React.FC<InlineEditInputComponentProps> = ({
  defaultValue = '',
  isEditable = false,
  validate,
  onSave,
}) => {
  // STATES
  const [internalValue, setInternalValue] = useState(defaultValue)
  const [isEditing, setIsEditing] = useState(false)
  const [isError, setIsError] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const saveButtonWrapperRef = useRef<HTMLButtonElement | null>(null)

  // HANDLERS
  // Triggered when enabling edit
  const handleEdit = () => {
    setIsEditing(true) // Enter edit mode
  }

  // Handle user input
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    setInternalValue(newValue)
    if (validate) {
      setIsError(!validate(newValue))
    }
  }

  // Triggered when canceling edits
  const handleCancel = useCallback(() => {
    setInternalValue(defaultValue) // Reset to initial value
    setIsEditing(false) // Exit edit mode
    setIsError(false) // Clear any errors
  }, [defaultValue])

  // Triggered when saving edits
  const handleSave = () => {
    onSave(internalValue) // Send value to parent
    setIsEditing(false) // Exit edit mode
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !isError) {
      handleSave()
    } else if (e.key === 'Escape') {
      handleCancel()
    }
  }

  // HOOKS
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if click target is outside of both the textarea and save button
      if (
        isEditing &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        saveButtonWrapperRef.current &&
        !saveButtonWrapperRef.current.contains(event.target as Node)
      ) {
        handleCancel() // Discard changes
      }
    }

    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [defaultValue, isEditing, handleCancel])

  return (
    <div className='flex items-center max-w-fit gap-2'>
      {isEditing ? (
        <>
          <input
            ref={inputRef}
            type='text'
            value={internalValue}
            readOnly={!isEditing}
            className={`w-full rounded-lg border ${
              isError ? 'border-red-500 bg-red-50' : isEditing ? 'border-custom-yellow' : 'border-gray-300'
            } shadow focus:outline-none px-1 py-1 transition-colors duration-200`}
            onChange={handleInput}
            onKeyDown={handleKeyDown}
          />
          <button ref={saveButtonWrapperRef} disabled={isError} onClick={handleSave}>
            <CheckIcon />
          </button>
        </>
      ) : (
        <>
          <span className='text-[#272D37] text-[15px] font-semibold'>{defaultValue}</span>
          {isEditable && (
            <button onClick={handleEdit}>
              <EditFilledIcon />
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default InlineEditInputComponent
