import React from 'react'
import './index.css'

const RadioMobileComponent = (props: any): JSX.Element => {
  const { row, label, radios } = props ?? {}
  return (
    <div className='w-full'>
      {label && <label className='text-gray-700'>{label}</label>}
      <div className={`flex ${row ? 'flex-row' : 'flex-col'} justify-between w-full`}>
        {radios.map((radio: any, index: number) => (
          <label
            key={index}
            className={`relative flex justify-center items-center text-headings cursor-pointer bg-custom-yellow lg:bg-transparent lg:border-b-2 lg:border-yellow-bold  px-2 py-2 rounded-lg lg:rounded-none ${
              !radio.isChecked ? 'bg-white text-disable md:border-none ' : ''
            } ${radio.disable ? 'bg-white text-disable md:border-none' : ''}`}
            style={{
              flex: '1 1 0',
              marginRight: index !== radios.length - 1 ? '8px' : '0px', // Adjust the margin for all except the last element
            }}
          >
            <input
              type='radio'
              className='absolute opacity-0 cursor-pointer'
              checked={radio.isChecked}
              onChange={radio.onChange}
              disabled={radio.disable}
              data-testid={radio.data_testid}
            />
            <span className={`text-sm font-medium ${!radio.isChecked ? 'text-disable' : ''}`}>{radio.label}</span>
          </label>
        ))}
      </div>
    </div>
  )
}

export default RadioMobileComponent
