import { Box } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { BillingDisabledComponent, BillingPaymentComponent } from 'components/billing-settings'
import InputMobileComponent from 'components/input-mobile'
import PaperComponent from 'components/paper'
import SwitchComponent from 'components/switch'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import React, { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import {
  isBillingDisabledForFamily,
  parseJwt,
  passwordTooltip,
  reenterPasswordTooltip,
  userSubscriptionLabel,
} from 'utils/constants'
import { MessageProps, StudentProps, SubscriptionProps } from 'utils/types'
import { getUser, signInUser, updateUserPassword } from 'utils/users'
import { isMatchingPassword, isValidPassword } from 'utils/validations'
import './index.css'

interface Props {
  tokens: any
  students: StudentProps[]
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Tokens, Students, User Relation, and Callback
  const { tokens, students } = props ?? {}
  const { AccessToken: accessToken, IdToken } = tokens
  const phoneNumber: string = parseJwt(IdToken).phone_number

  // states
  // Change Password States
  const [password, setPassword] = useState({ current: '', new: '', reenteredNew: '' })
  // Billing States
  // Two sources of truth: School subscription (MASTER) & Family subscription (SLAVE)
  // 1. Easy Scenario...
  //    Family registered for 1 school => school has subscribed => No family subscriptions required => No Billing Settings
  // 2. Hard Scenario...
  //    Family registered for 3 school => all schools have subscribed => No family subscriptions required => No Billing Settings
  // 3. Crazy Scenario...
  //    Family registered for 3 school => 2 schools have NOT subscribed => Family subscriptions required => Billing Settings Shown
  //    We will charge for 2 schools (total registered schools minus schools that have subscribed)
  const isShowBillingSettings: boolean = false // students?.every((v: any) => v?.isSchoolSubscription) ? false : true
  const [isUserSubscription, setIsUserSubscription] = useState<boolean>(
    students?.some((v: any) => v?.isStudentSubscription) ? true : false,
  )
  const [userSubscription, setUserSubscription] = useState<SubscriptionProps>()

  // Common States
  const [msg, setMsg] = useState<MessageProps>()
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [isLoading, setIsLoading] = React.useState<string>('')

  // hooks
  useEffect(() => {
    const loadUserData = async () => {
      const { data, msg, error } = await getUser(phoneNumber)
      if (error) setMsg(msg)
      else if (data) {
        // const u = data?.user
        // setIsUserSubscription(u?.userSubscription?.billingCycle ? true : false)
        // setUserSubscription(u?.userSubscription)
      }
      setIsFirstLoad(false)
    }
    loadUserData()
  }, [phoneNumber])

  // handlers
  const handleCancel = () => navigate('/')
  const handleSave = async () => {
    try {
      setIsLoading('Updating user password...')

      const { msg, error } = await updateUserPassword(phoneNumber, accessToken, password.current, password.new)

      if (error) {
        setMsg(msg)
      } else {
        const { msg, error } = await signInUser(phoneNumber, password.new)

        if (error) {
          setMsg(msg)
        } else {
          setPassword({ current: '', new: '', reenteredNew: '' })
          setMsg({ style: 'success', text: 'Password updated successfully.' })
        }
      }
    } catch (err) {
      setMsg({ style: 'error', text: 'An unexpected error occurred.' })
    } finally {
      setIsLoading('')
    }
  }

  if (isFirstLoad) return <LinearProgress />

  const isPasswordValid: boolean =
    isValidPassword(password.current, true) &&
    isValidPassword(password.new, true) &&
    isMatchingPassword(password.reenteredNew, password.new, true)

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Display change password *****************/}
      <WizardComponent
        title={'Change Password'}
        onClick={handleSave}
        onBack={handleCancel}
        onCancel={handleCancel}
        btnText={'Save'}
        data_testId='Save-testid'
        disable={!isPasswordValid}
      >
        <Box display='flex' flexDirection='column' justifyContent='center' paddingTop={2} gap={2}>
          <InputMobileComponent
            type='Password'
            data_testid='current-password-id'
            labeltext='Current Password'
            isError={!isValidPassword(password.current)}
            onChange={(v: string) => setPassword({ ...password, current: v })}
          />
          <InputMobileComponent
            type='Password'
            data_testid='new-password-id'
            labeltext='New Password'
            isError={!isValidPassword(password.new)}
            helperText={!isValidPassword(password.new) ? passwordTooltip() : ''}
            onChange={(v: string) => setPassword({ ...password, new: v })}
          />
          <InputMobileComponent
            type='Password'
            data_testid='reenter-password-id'
            labeltext='Re-enter New Password'
            isError={!isMatchingPassword(password.reenteredNew, password.new)}
            helperText={!isMatchingPassword(password.reenteredNew, password.new) ? reenterPasswordTooltip() : ''}
            onChange={(v: string) => setPassword({ ...password, reenteredNew: v })}
          />
        </Box>
      </WizardComponent>

      {/***************** Display Subscription *****************/}
      {isShowBillingSettings && (
        <PaperComponent title={`Subscription`}>
          <SwitchComponent isChecked={isUserSubscription} onChange={() => setIsUserSubscription(!isUserSubscription)}>
            {userSubscriptionLabel(isUserSubscription)}
          </SwitchComponent>
          {isUserSubscription && (
            <>
              {isBillingDisabledForFamily ? (
                <BillingDisabledComponent
                  body={`Online subscription management is currently in development. Please contact our support team at support@carpool.school to activate your free subscription.`}
                  onSubmit={(v: SubscriptionProps) => setUserSubscription({ ...userSubscription, ...v })}
                />
              ) : (
                <BillingPaymentComponent
                  name={phoneNumber}
                  paymentMethod={userSubscription?.paymentMethod}
                  onSubmit={(v: string) =>
                    setUserSubscription({ ...userSubscription, paymentMethodId: v, billingCycle: 'MONTHLY_ROLLING' })
                  }
                />
              )}
            </>
          )}
        </PaperComponent>
      )}
    </>
  )
}

export default Index
