import SelectMobileComponent from 'components/select-mobile'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { getSessionItem, removeSessionItem } from 'utils/constants'
import { enabledRelationships, registerStudent } from 'utils/students'
import { MessageProps } from 'utils/types'
import { isValidRelationship, isValidScheduleGroup } from 'utils/validations'
import './index.css'

interface Props {
  onAdd: (ssi: string, cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name, Code, Schedule Groups, Selected Schedule and onAdd Callbck
  const { onAdd } = props ?? {}
  const schoolName: string = getSessionItem('x-pool-sn') || ''
  const schoolCode: string = getSessionItem('x-pool-sc') || ''
  const schoolScheduleGroupNames: string[] = getSessionItem('x-pool-ssgn') || ''
  const selectedScheduleGroup: string = getSessionItem('x-pool-sg') || ''

  // states
  const [userStudentRelationship, setUserStudentRelationship] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()

  // handlers
  const onBack = () => navigate(-1)
  const onSubmit = async (): Promise<any> => {
    // Register Student
    const { msg, data, error } = await registerStudent(
      schoolName,
      schoolCode,
      userStudentRelationship,
      selectedScheduleGroup,
      { address: '' },
      { address: '' },
    )
    if (error) setMsg(msg)
    else {
      // Clean up all saved items from add workflow
      removeSessionItem('x-pool-sn')
      removeSessionItem('x-pool-sa')
      removeSessionItem('x-pool-sc')
      removeSessionItem('x-pool-ssgn')
      removeSessionItem('x-pool-sg')
      // Return
      const id: string = (data?.studentId as string) ?? ''
      onAdd(id, () => {
        navigate(`/students?id=${id}`)
      })
    }
  }
  const isValid: boolean =
    isValidRelationship(userStudentRelationship, true) &&
    isValidScheduleGroup(schoolScheduleGroupNames, selectedScheduleGroup, true)

  return (
    <WizardComponent
      onBack={onBack}
      title={'Select Your Relationship to the Student'}
      disable={!isValid}
      onClick={onSubmit}
      btnText='Save'
      data_testId='save_testid'
    >
      <SelectMobileComponent
        data_testid='relationship_testid'
        isRequired={true}
        isError={!isValidRelationship(userStudentRelationship)}
        options={enabledRelationships}
        value={userStudentRelationship}
        onChange={(v: string) => setUserStudentRelationship(v)}
      />
      {/* <ButtonComponent text='Register' disable={!isValid} endIcon={<ArrowForwardIcon />} onClick={onSubmit} /> */}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </WizardComponent>
  )
}

export default Index
