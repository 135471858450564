import {
  GetPlaceCommand,
  GetPlaceCommandOutput,
  SearchForSuggestionsResult,
  SearchPlaceIndexForSuggestionsCommand,
  SearchPlaceIndexForSuggestionsCommandOutput,
} from '@aws-sdk/client-location'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import InputAddressMobileComponent from 'components/input-address-mobile'
import { useCallback, useState } from 'react'
import { getAwsLocationClient, replaceCountryWithCode } from 'utils/map'
import { LocationProps } from 'utils/types'

// Define the props for the component
interface Suggestion {
  Text: string
  PlaceId: string
}
interface PlacesAutocompleteProps {
  isRequired?: boolean
  isDisabled?: boolean
  label: string
  placeholder?: string
  defaultValue?: string
  bounds?: number[]
  helperText?: string
  onChange: (address: string) => void
  onSubmit: (location: LocationProps) => void
  onMapClick: () => void
  dataTestid?: string
  tooltip?: any
}

const PlacesAutocompleteComponent = (props: PlacesAutocompleteProps): JSX.Element => {
  const {
    isRequired,
    isDisabled,
    label,
    placeholder,
    defaultValue,
    bounds,
    helperText,
    onChange,
    onSubmit,
    onMapClick,
    dataTestid,
    tooltip,
  } = props ?? {}
  const [inputValue, setInputValue] = useState<string>(defaultValue ?? '')
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])

  // hooks
  /* eslint @typescript-eslint/no-var-requires: "off" */
  const debounce = require('lodash/debounce')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSuggestions = useCallback(
    debounce(async (Text: string) => {
      const options: Suggestion[] = []
      if (Text.length > 2) {
        try {
          const awsLocationClient = await getAwsLocationClient()
          const data: SearchPlaceIndexForSuggestionsCommandOutput = await awsLocationClient.send(
            new SearchPlaceIndexForSuggestionsCommand({
              IndexName: process.env.REACT_APP_AWS_PLACE_INDEX,
              Text,
              FilterBBox: bounds,
              MaxResults: 5,
            }),
          )
          data?.Results?.forEach((v: SearchForSuggestionsResult) => {
            if (v.Text && v.PlaceId) options.push({ Text: v.Text, PlaceId: v.PlaceId })
          })
        } catch (error) {
          console.error('Error fetching suggestions:', error)
        }
      }
      setSuggestions(options)
    }, 300), // Debounce delay in milliseconds
    [],
  )

  // handlers
  const handleInputChange = async (text: string) => {
    setInputValue(text)
    onChange(text)
    debouncedFetchSuggestions(text)
  }
  const handleSelectSuggestion = async (placeId: string) => {
    try {
      const awsLocationClient = await getAwsLocationClient()
      const data: GetPlaceCommandOutput = await awsLocationClient.send(
        new GetPlaceCommand({
          IndexName: process.env.REACT_APP_AWS_PLACE_INDEX,
          PlaceId: placeId,
        }),
      )
      if (
        data?.Place &&
        data.Place.Label &&
        data?.Place?.Geometry &&
        data.Place.Geometry.Point?.length &&
        data.Place.Geometry.Point?.length >= 2
      ) {
        const address: string = replaceCountryWithCode(data.Place.Label)
        const result: LocationProps = {
          address,
          lat: data.Place.Geometry.Point[1],
          lng: data.Place.Geometry.Point[0],
          placeId,
        }
        setInputValue(address)
        setSuggestions([])
        onSubmit(result)
      } else throw new Error('Unexpected error')
    } catch (error) {
      console.error('Error fetching suggestions:', error)
    }
  }

  const children: JSX.Element = (
    <>
      <InputAddressMobileComponent
        isRequired={isRequired}
        isDisabled={isDisabled}
        label={label}
        placeholder={placeholder}
        value={inputValue}
        helperText={helperText}
        onChange={handleInputChange}
        onMapClick={onMapClick}
        data-testid={dataTestid}
      />
      <List>
        {suggestions.map((suggestion: Suggestion, index: number) => (
          <ListItemButton key={index} onClick={() => handleSelectSuggestion(suggestion.PlaceId)}>
            <InputAdornment position='start'>
              <LocationOnIcon sx={{ fontSize: '1rem' }} />
            </InputAdornment>
            <ListItemText
              primary={suggestion.Text}
              primaryTypographyProps={{ sx: { fontSize: '0.875rem', color: '#666666' } }}
            />
          </ListItemButton>
        ))}
      </List>
    </>
  )

  return tooltip ? (
    <Tooltip title={tooltip} followCursor disableHoverListener>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  )
}

export default PlacesAutocompleteComponent
