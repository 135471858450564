import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

interface CircularProgressWithLabelProps {
  value?: number
}

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({ value }) => {
  return (
    <center>
      {/* Circular Loader with percentage */}
      <Box sx={{ position: 'relative', display: 'inline-flex', overflow: 'hidden' }}>
        <CircularProgress
          variant={value ? 'determinate' : 'indeterminate'}
          value={value}
          color='secondary'
          thickness={8}
          size={60}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          {/* Uploading percentage */}
          {value && (
            <Typography variant='caption' component='div' color='text.secondary' sx={{ fontSize: '14px' }}>
              {`${Math.round(value)}%`}
            </Typography>
          )}
        </Box>
      </Box>
    </center>
  )
}

export default CircularProgressWithLabel
