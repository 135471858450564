interface TextAreaComponentProps {
  isRequired?: boolean
  isDisabled?: boolean
  isError?: boolean
  type?: string
  labeltext?: string
  placeholder?: string
  value?: any
  helperText?: string
  marginLeft?: string
  onChange: (v: string) => void
  onEnterKey?: () => void
  data_testid?: string
  min?: number
  step?: number
  max?: number
  isReadOnly?: boolean
}

const TextAreaComponent = (props: TextAreaComponentProps): JSX.Element => {
  const {
    isRequired,
    isDisabled,
    isError,
    type,
    labeltext,
    placeholder,
    value,
    helperText,
    marginLeft,
    onChange,
    onEnterKey,
    data_testid,
    isReadOnly,
    ...rest
  } = props ?? {}

  return (
    <div className='flex flex-col items-start gap-2 w-full'>
      <label className='text-gray-400 text-sm font-medium leading-[1.42857]'>{labeltext}</label>
      <textarea
        data-testid={data_testid}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder}
        className={`w-full rounded-lg border ${isError ? 'border-red-500' : 'border-gray-300'}
        bg-white shadow-xs focus:outline-none h-36 p-[10px] pr-[14px] items-start ${marginLeft}`}
        {...rest}
      />
      <div className='text-gray-400 text-sm font-medium leading-normal'>{helperText}</div>
    </div>
  )
}

export default TextAreaComponent
