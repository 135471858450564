import {
  CreateMessageInputProps,
  CreateMessageOutputProps,
  GetMessagesInputProps,
  GetMessagesOutputProps,
  MessageOutputProps,
  SortDirection,
} from 'types/MessageTypes'
import {
  AddRoomUsersInputProps,
  CreateRoomInputProps,
  RemoveRoomUsersInputProps,
  RoomType,
  UpdateRoomInputProps,
  UpdateRoomUserInputProps,
} from 'types/RoomTypes'
import { connectToWss, httpBearerAuth, processError } from 'utils/requests'
import { ResponseProps } from 'utils/types'

export const createRoom = async (
  roomType: RoomType,
  roomUsers: string[],
  roomName?: string,
  roomDescription?: string,
  roomCommunityId?: string,
): Promise<ResponseProps> => {
  try {
    const body: CreateRoomInputProps = { roomType, roomUsers, roomName, roomDescription, roomCommunityId }
    const { data } = await httpBearerAuth('post', `/api/rooms`, '', body as unknown as Record<string, unknown>)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateRoom = async (
  roomId: string,
  roomType?: RoomType,
  roomName?: string,
  roomDescription?: string,
  roomAdmins?: string[],
): Promise<ResponseProps> => {
  try {
    const body: UpdateRoomInputProps = { roomType, roomName, roomDescription, roomAdmins }
    const { data } = await httpBearerAuth('patch', `/api/rooms/${roomId}`, '', body as Record<string, unknown>)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const deleteRoom = async (roomId: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('delete', `/api/rooms/${roomId}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getRoom = async (roomId: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/rooms/${roomId}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getRooms = async (): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/rooms`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getRoomUsers = async (roomId: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/rooms/${roomId}/users`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const addRoomUsers = async (roomId: string, roomUsers: string[]): Promise<ResponseProps> => {
  try {
    const body: AddRoomUsersInputProps = { roomUsers }
    const { data } = await httpBearerAuth(
      'post',
      `/api/rooms/${roomId}/users`,
      '',
      body as unknown as Record<string, unknown>,
    )
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

// Fire and forget - not async
export const updateRoomUser = (roomId: string, userId: string, userLastSeen: string): void => {
  const body: UpdateRoomUserInputProps = { userLastSeen }
  httpBearerAuth('patch', `/api/rooms/${roomId}/users/${userId}`, '', body as unknown as Record<string, unknown>)
}

export const removeRoomUsers = async (roomId: string, roomUsers: string[]): Promise<ResponseProps> => {
  try {
    const body: RemoveRoomUsersInputProps = { roomUsers }
    const { data } = await httpBearerAuth(
      'delete',
      `/api/rooms/${roomId}/users`,
      '',
      body as unknown as Record<string, unknown>,
    )
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const createRoomMsg = async (
  userId: string,
  msgRoomId: string,
  msgText: string,
): Promise<ResponseProps<{ data: CreateMessageOutputProps }>> => {
  try {
    const query: string =
      'mutation CreateMessage($input: messageInput!) { createMessage(input: $input) { msgRoomId msgText msgSentBy msgSentAt } }'
    const input: CreateMessageInputProps = { msgRoomId, msgText }
    const { data } = await httpBearerAuth('post', `/graphql/api`, '', { query, variables: { input } })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getRoomMsgs = async (
  userId: string,
  msgRoomId: string,
  limit?: number,
  nextToken?: string | null,
  sortDirection?: SortDirection,
): Promise<ResponseProps<{ data: GetMessagesOutputProps }>> => {
  try {
    const query: string =
      'query GetMessages($input: getMessagesInput!) { getMessages(input: $input) { items { msgText msgSentBy msgSentAt } nextToken } }'
    const input: GetMessagesInputProps = { msgRoomId, limit, nextToken, sortDirection }
    const { data } = await httpBearerAuth('post', `/graphql/api`, '', { query, variables: { input } })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const onCreateMessageSubscription = async (
  msgRoomId: string,
  subscriberId: string,
  onCreatedMessage: (message: MessageOutputProps) => void,
): Promise<() => void> => {
  const query: string = `subscription onCreateMessage($msgRoomId: ID!) { onCreateMessage(msgRoomId: $msgRoomId) { msgText msgSentBy msgSentAt } }`
  return await connectToWss({ query, variables: { msgRoomId } }, subscriberId, onCreatedMessage)
}
