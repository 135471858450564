import {
  ActionPerformed,
  PermissionStatus,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications'
import { getItem, isPushNotification, setItem } from './constants'

const setDeviceId = (v: any): void => setItem('device', v)
export const getDeviceId = (): string => getItem('device')

export const registerNotifications = async (): Promise<void> => {
  if (isPushNotification) {
    // Registration
    let permStatus: PermissionStatus = await PushNotifications.checkPermissions()
    if (permStatus.receive !== 'granted') permStatus = await PushNotifications.requestPermissions()
    if (permStatus.receive === 'granted') PushNotifications.register()
    else setDeviceId('PermissionNotGranted')
    // Listener to trap registration events
    PushNotifications.addListener('registrationError', (error: any) => setDeviceId('RegistrationError'))
    PushNotifications.addListener('registration', (token: Token) => setDeviceId(token.value))
    // Listener to remove all delivered notifications when app is open
    PushNotifications.addListener('pushNotificationReceived', async (n: PushNotificationSchema) => {
      window.location.replace(n.data.url)
    })
    // Listener to open the appropriate page when app is closed
    PushNotifications.addListener('pushNotificationActionPerformed', (a: ActionPerformed) => {
      window.location.replace(a.notification.data.url)
    })
  }
}
