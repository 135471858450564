import SelectMobileComponent from 'components/select-mobile'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { getSessionItem, setSessionItem } from 'utils/constants'
import { MessageProps } from 'utils/types'
import { isValidScheduleGroup } from 'utils/validations'
import './index.css'

function Index(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Schedule Groups
  const schoolScheduleGroupNames: string[] = getSessionItem('x-pool-ssgn') || ''

  // states
  const [selectedScheduleGroup, setSelectedScheduleGroup] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()

  // handlers
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    // Save schedule group for following steps
    setSessionItem('x-pool-sg', selectedScheduleGroup)
    navigate('/signup/createUser')
  }

  const isValid: boolean = isValidScheduleGroup(schoolScheduleGroupNames, selectedScheduleGroup, true)

  return (
    <WizardComponent
      onBack={onBack}
      title={'Select School Schedule'}
      subtitle={`Choose one that matches your grade and dismissal time`}
      disable={!isValid}
      btnText={'Next'}
      onClick={onNext}
      data_testId={'next-btn-testid'}
    >
      <SelectMobileComponent
        data_testid='schedule_testid'
        isRequired={true}
        isError={!isValidScheduleGroup(schoolScheduleGroupNames, selectedScheduleGroup)}
        options={schoolScheduleGroupNames} // Options from the fetched data
        value={selectedScheduleGroup}
        onChange={(v: string) => setSelectedScheduleGroup(v)}
      />
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </WizardComponent>
  )
}

export default Index
