import React from 'react'
import './index.css'

const CircleLoader = (): JSX.Element => {
  return (
    // <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    //   <div className="w-8 h-8 border-4 border-t-4 border-gray-200 border-t-yellow-400 rounded-full animate-spin"></div>
    // </div>
    //     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]">
    //   <div className="w-[34px] h-[34px] border-4 border-[#f7f5ec] rounded-full border-t-[#e9e040] animate-spin"></div>
    // </div>
    <>
      <div className={`loader-container`}>
        <div className={`circle-loader`}></div>
      </div>
    </>
  )
}

export default CircleLoader
