import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputComponent from 'components/input'
import NavComponent from 'components/nav'
import SelectComponent from 'components/select'
import TextComponent from 'components/text'
import { formatIncompletePhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max'
import { useEffect, useState } from 'react'
import { baseURL, defaultPhoneNumberCountry, phoneNumberRatesTooltip, phoneNumberTooltip } from 'utils/constants'
import { AddUserProps } from 'utils/types'
import { isValidName, isValidPhone, isValidScheduleGroup } from 'utils/validations'
import './index.css'

const AddUsers = (props: any): JSX.Element => {
  const { isDisable, onAdd, scheduleGroupNames } = props ?? {}
  const [isShow, setIsShow] = useState(false)
  const [vars, setVars] = useState<AddUserProps>({
    userName: '',
    userPhoneNumber: '',
    scheduleGroupName: '',
  })
  const [sendSMS, setSendSMS] = useState(false)
  const [isConsent, setIsConsent] = useState(false)
  const { userName, userPhoneNumber, scheduleGroupName } = vars ?? {}
  const formattedPhoneNumber: string = formatIncompletePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry)
  const e164PhoneNumber: string = isValidPhone(userPhoneNumber, true)
    ? parsePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry).format('E.164')
    : ''
  const isValid: boolean =
    isValidName(userName, true) &&
    isValidScheduleGroup(scheduleGroupNames, scheduleGroupName, true) &&
    isValidPhone(userPhoneNumber, true) &&
    isConsent

  // Handling the scheduleGroupNames dynamic nature
  useEffect(() => {
    // Initialize scheduleGroupName based on the length of scheduleGroupNames
    setVars({
      ...vars,
      scheduleGroupName: scheduleGroupNames?.length === 1 ? scheduleGroupNames[0] : '',
    } as AddUserProps)
  }, [scheduleGroupNames]) // eslint-disable-line react-hooks/exhaustive-deps

  // Function to handle uploading dialog open
  const handleDialogOpen = () => {
    setIsShow(true)
  }

  // Function to handle file upload
  const handleDialogSubmit = async () => {
    setIsShow(false) // Close the dialog after file upload clicked
    onAdd({ userPhoneNumber: e164PhoneNumber, userName, scheduleGroupName }, sendSMS)
    setVars({ userName: '', userPhoneNumber: '', scheduleGroupName: '' })
    setSendSMS(false)
    setIsConsent(false)
  }

  // Function to handle dialog close
  const handleDialogClose = () => {
    setVars({ userName: '', userPhoneNumber: '', scheduleGroupName: '' })
    setIsShow(false)
    setSendSMS(false)
    setIsConsent(false)
  }

  return (
    <div>
      {/* File Upload Button */}
      <Button
        disabled={isDisable}
        color='primary'
        onClick={handleDialogOpen}
        sx={{ fontSize: 13 }}
        data-testid='add-user-button'
      >
        <AddIcon sx={{ fontSize: 20 }} />
        Add
      </Button>
      {/* Dialog for Entering Data and Starting User Addition */}
      <Dialog open={isShow} onClose={handleDialogClose} data-testid='modal-dialog'>
        <Button onClick={handleDialogClose} sx={{ position: 'absolute', top: '5%', right: 0 }}>
          <CloseIcon sx={{ fontSize: 20 }} />
        </Button>
        <DialogTitle sx={{ textAlign: 'center' }}>Configure User to Add</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            {/* Phone Number */}
            <InputComponent
              key='5c'
              type='text'
              label='Phone number of adult'
              isRequired={true}
              isError={!isValidPhone(userPhoneNumber)}
              helperText={
                !isValidPhone(userPhoneNumber)
                  ? phoneNumberTooltip()
                  : isValidPhone(userPhoneNumber, true)
                    ? phoneNumberRatesTooltip()
                    : ''
              }
              onChange={(v: string) => setVars({ ...vars, userPhoneNumber: v } as AddUserProps)}
              value={isValidPhone(userPhoneNumber) ? formattedPhoneNumber : userPhoneNumber}
            />
            {/* Full Name */}
            <InputComponent
              key='5b'
              type='text'
              label='Full name of adult'
              isRequired={true}
              isError={!isValidName(userName)}
              onChange={(v: string) => setVars({ ...vars, userName: v } as AddUserProps)}
            />
            {/* Schedule */}
            {scheduleGroupNames.length > 1 && (
              <SelectComponent
                key='1c'
                label='Select School Schedule' // Label for the dropdown
                isRequired={true}
                isError={!isValidScheduleGroup(scheduleGroupNames, scheduleGroupName)}
                options={scheduleGroupNames} // Options from the fetched data
                value={scheduleGroupName}
                onChange={(v: string) => setVars({ ...vars, scheduleGroupName: v } as AddUserProps)}
              />
            )}
          </Box>
          {/* Checkbox for sending SMS messages */}
          <FormControlLabel
            control={<Checkbox checked={sendSMS} color='warning' onChange={(e) => setSendSMS(e.target.checked)} />}
            label={<TextComponent size='body2'>Send account activation link via SMS to user</TextComponent>}
            data-testid='send-sms-message-checkbox'
          />
          {/* Checkbox for consent */}
          <FormControlLabel
            control={<Checkbox checked={isConsent} color='warning' onChange={(e) => setIsConsent(e.target.checked)} />}
            label={
              <TextComponent size='body2'>
                I confirm that I have the authority to share this parent data with Carpool School Inc. This information
                will be governed by the Carpool School Inc.{' '}
                <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
                  <NavComponent href={`${baseURL}/home/index.html#privacy`} text='Privacy Policy' isOpenNewTab={true} />
                </span>
                .
              </TextComponent>
            }
            data-testid='consent-checkbox'
          />
          <center>
            <Button
              disabled={!isValid}
              onClick={handleDialogSubmit}
              color={'secondary'}
              variant='contained'
              sx={{ display: 'flex', alignItems: 'center' }}
              startIcon={<AddIcon sx={{ fontSize: 20 }} />}
              data-testid='modal-dialog-action-submit'
            >
              Add User
            </Button>
          </center>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddUsers
