import Chip from '@mui/material/Chip'
import './index.css'

const BadgeComponent = (props: any): JSX.Element => {
  const { color, variant, size, text } = props
  const children: JSX.Element = (
    <Chip size={size ?? 'small'} label={text} color={color || 'default'} variant={variant || 'filled'} />
  )
  return children
}

export default BadgeComponent
