import { Component, PropsWithChildren } from 'react'
import { isProd } from 'utils/constants'

export default class ErrorBoundary extends Component<PropsWithChildren, { error: any; errorInfo: any }> {
  constructor(props: PropsWithChildren) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <>
          <h2>Something went wrong :(</h2>
          <p>
            This is most likely our fault, but you can try refreshing the app. If error persists please email or message
            us. Sorry for the trouble!
          </p>
          <br />
          {!isProd() ? (
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          ) : (
            <></>
          )}
        </>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}
