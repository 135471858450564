import { Checkbox, Dialog, FormControlLabel, LinearProgress, Radio, styled } from '@mui/material'
import AvatarComponent from 'components/avatar'
import BackComponent from 'components/back'
import ButtonMobileComponent from 'components/button-mobile'
import InputMobileComponent from 'components/input-mobile'
import SelectMobileComponent from 'components/select-mobile'
import TextAreaComponent from 'components/textarea'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { getDistance } from 'geolib'
import Arrow from 'images/arrow_forward.png'
import React, { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { RoomType, UserRoomsOutputProps } from 'types/RoomTypes'
import { UsersOutputProps } from 'types/UserTypes'
import { isWeb, MAX_ALLOWED_GROUP_MEMBERS, METERS_TO_MILES, titleCaseSchoolNameForUI } from 'utils/constants'
import { createRoom } from 'utils/rooms'
import { MessageProps, PositionProps } from 'utils/types'
import { isValidGroupDescription, isValidGroupName } from 'utils/validations'

const CustomRadio = styled(Radio)(({ theme }) => ({
  height: '20px',
  marginLeft: '4px',
  color: '#A3A3A3', // Default color when not checked
  width: '16px',
  '&.Mui-checked': {
    color: '#EBD76F', // Color when checked
  },
}))

interface RoomCreateComponentProps {
  users: UsersOutputProps[] // all users
  privateRooms: UserRoomsOutputProps[] // all existing private user rooms
  communityIds: string[] // all communities
  defaultCommunityId?: string
  userId: string // current user creating/adding
  onUpdate: (cb?: () => void) => void
}

interface CardComponentProps {
  isChecked: boolean
  avatar?: JSX.Element
  title: string
  subtitle?: string
  body?: string
  onChange: (isChecked: boolean) => void
}

const CardComponent = (props: CardComponentProps): JSX.Element => {
  const { isChecked, avatar, title, subtitle, body, onChange } = props ?? {}

  return (
    <div className={`flex flex-col my-4`}>
      <FormControlLabel
        control={
          <Checkbox
            value={isChecked}
            onChange={(e) => onChange(e.target.checked)}
            sx={{
              strokeWidth: 1,
              '&.Mui-checked': {
                color: '#EBD76F',
              },
            }}
          />
        }
        label={
          <div className='flex gap-2 flex-1'>
            {avatar}
            <div className='flex flex-col'>
              <div className='flex items-center gap-1'>
                <span className='text-[#474747] text-sm font-medium'>{title}</span>
                {subtitle && (
                  <>
                    <span className='w-[1px] bg-gray-300 h-[20px]' />
                    <span className='text-[#797979] text-xs font-medium'>{subtitle}</span>
                  </>
                )}
              </div>
              <span className='text-[#FF7900] text-sm font-normal'>{body}</span>
            </div>
          </div>
        }
      />
    </div>
  )
}

const RoomCreateComponent: React.FC<RoomCreateComponentProps> = ({
  users,
  privateRooms,
  communityIds,
  defaultCommunityId,
  userId,
  onUpdate,
}) => {
  const navigate: NavigateFunction = useNavigate()
  const filters: string[] = Array.from(communityIds).sort()

  // State for selected school and room type filter
  const [selectedFilter, setSelectedFilter] = useState<string>(
    titleCaseSchoolNameForUI(defaultCommunityId || filters[0]),
  )
  const [filteredUsers, setFilteredUsers] = useState<CardComponentProps[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set())
  const [showModal, setShowModal] = useState<boolean>(false)
  const [roomName, setRoomName] = useState<string>('')
  const [roomDescription, setRoomDescription] = useState<string>('')
  const [roomType, setRoomType] = useState<RoomType>(RoomType.GROUP)
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // hooks
  useEffect(() => {
    const cards: (CardComponentProps & { distance: number })[] = []
    const selectedFilterUsers: UsersOutputProps[] = users.filter(
      (v) => selectedFilter === titleCaseSchoolNameForUI(v.userCommunityId),
    )
    const currentUserPickupLocation: PositionProps | undefined = selectedFilterUsers.find(
      (v) => userId === v.userId && v.userCommunityStudentPickupLocation,
    )?.userCommunityStudentPickupLocation
    const currentUserDropoffLocation: PositionProps | undefined = selectedFilterUsers.find(
      (v) => userId === v.userId && v.userCommunityStudentDropoffLocation,
    )?.userCommunityStudentDropoffLocation
    selectedFilterUsers
      .filter((v) => userId !== v.userId)
      .forEach((v: UsersOutputProps) => {
        const {
          userName,
          userRelationship,
          userPhotoLink,
          userCommunityStudentPickupLocation,
          userCommunityStudentDropoffLocation,
        } = v
        // Only show users that have matching morning and/or afternoon caprool requests
        // and use the afternoon carpool distance when the you have both matching
        let distance: number | undefined
        if (currentUserDropoffLocation && userCommunityStudentDropoffLocation)
          distance = getDistance(currentUserDropoffLocation, userCommunityStudentDropoffLocation)
        else if (currentUserPickupLocation && userCommunityStudentPickupLocation)
          distance = getDistance(currentUserPickupLocation, userCommunityStudentPickupLocation)
        if (distance !== undefined) {
          cards.push({
            isChecked: false,
            avatar: <AvatarComponent name={userName} photoLink={userPhotoLink} />,
            title: userName,
            subtitle: userRelationship,
            body: Math.round(distance * METERS_TO_MILES * 10) / 10 + ' miles away',
            onChange: (isChecked: boolean) =>
              isChecked
                ? setSelectedUsers((prev) => new Set(prev).add(v.userId))
                : setSelectedUsers((prev) => {
                    const curr = new Set(prev)
                    curr.delete(v.userId)
                    return curr
                  }),
            distance,
          })
        }
      })
    setSelectedUsers(new Set())
    setFilteredUsers(cards.sort((a, b) => a.distance - b.distance))
  }, [userId, users, selectedFilter])

  // handlers
  const handleContinue = async () => {
    if (selectedUsers.size === 1) {
      // Redirerct to existing private chat room if they exist
      const roomId: string | undefined = privateRooms.find((v) =>
        v.roomMembers?.includes(Array.from(selectedUsers)[0]),
      )?.roomId
      if (roomId) navigate(`/messaging/${roomId}`)
      else {
        // Create private chat room
        const { msg, data, error } = await createRoom(RoomType.PRIVATE, [...Array.from(selectedUsers), userId])
        if (error) setMsg(msg)
        else if (data) {
          onUpdate(() => navigate(`/messaging/${data.roomId}`))
        }
      }
    } else if (selectedUsers.size <= MAX_ALLOWED_GROUP_MEMBERS) {
      setShowModal(true)
    } else setMsg({ text: `Groups have limit of ${MAX_ALLOWED_GROUP_MEMBERS} members` })
  }
  const handleCreateRoom = async () => {
    setIsLoading('Creating room...')
    // Create group rooms
    const { msg, data, error } = await createRoom(
      roomType,
      [...Array.from(selectedUsers), userId],
      roomName,
      roomDescription,
      filters.find((v) => titleCaseSchoolNameForUI(v) === selectedFilter),
    )
    if (error) setMsg(msg)
    else if (data) {
      onUpdate(() => navigate(`/messaging/${data.roomId}`))
    }
    setIsLoading('')
    setShowModal(false)
  }

  // body
  const body: JSX.Element = (
    <div className={`flex flex-col flex-1 self-stretch gap-2 ${isWeb ? '' : 'px-5 mb-[120px]'}`}>
      {/***************** Select Community *****************/}
      <SelectMobileComponent
        isRequired={true}
        isDisabled={filters.length === 1}
        options={filters.map((v) => titleCaseSchoolNameForUI(v))}
        value={selectedFilter}
        onChange={(v: string) => setSelectedFilter(v)}
      />
      {/* **************** User List **************** */}
      {filteredUsers.map((room: CardComponentProps, index: number) => (
        <CardComponent
          key={index}
          isChecked={room.isChecked}
          avatar={room.avatar}
          title={room.title}
          subtitle={room.subtitle}
          body={room.body}
          onChange={room.onChange}
        />
      ))}
      {/* **************** Modal **************** */}
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        scroll='paper'
        PaperProps={{ sx: { borderRadius: '12px', padding: '24px' } }}
      >
        <div className='flex flex-col gap-6 w-[279px]'>
          <div className='flex flex-col items-center text-center gap-2'>
            <p className='text-[18px] font-medium'>Group Name</p>
            <p className='text-[16px] font-normal text-gray-400'>Choose a name for your group and make it stand out!</p>
          </div>
          <InputMobileComponent
            type='text'
            labeltext='Group Name'
            isRequired={true}
            isError={!isValidGroupName(roomName)}
            value={roomName}
            onChange={(v: string) => setRoomName(v)}
          />
          <TextAreaComponent
            labeltext='Group Description'
            placeholder='Optional description...'
            isRequired={false}
            isError={!isValidGroupDescription(roomDescription)}
            value={roomDescription}
            onChange={(v: string) => setRoomDescription(v)}
          />
          <div className='flex flex-col gap-2'>
            <p className='text-xs font-medium'>Visibility</p>
            <div className='flex items-center gap-2'>
              <CustomRadio
                size='small'
                checked={roomType === RoomType.GROUP}
                onChange={() => setRoomType(RoomType.GROUP)}
                value={RoomType.GROUP}
              />
              <p className='text-sm font-medium'>Private</p>
              <span className='text-xs font-regular'>{`- Only selected users`}</span>
            </div>
            <div className='flex items-center gap-2'>
              <CustomRadio
                size='small'
                checked={roomType === RoomType.PUBLIC}
                onChange={() => setRoomType(RoomType.PUBLIC)}
                value={RoomType.PUBLIC}
              />
              <p className='text-sm font-medium'>Public</p>
              <span className='text-xs font-regular max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis'>
                {`- Anyone in ${selectedFilter}`}
              </span>
            </div>
          </div>
          <div className='flex gap-3 justify-center items-center'>
            <ButtonMobileComponent
              text='Cancel'
              width='w-[120px]'
              padx='px-[12px]'
              height='h-[40px]'
              color='bg-custom-gray'
              onClick={() => setShowModal(false)}
            />
            <ButtonMobileComponent
              text='Submit'
              width='w-[120px]'
              padx='px-[12px]'
              height='h-[40px]'
              disabled={!isValidGroupName(roomName, true) || !isValidGroupDescription(roomDescription)}
              onClick={handleCreateRoom}
            />
          </div>
        </div>
      </Dialog>
    </div>
  )

  // Filtered Rooms
  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Mobile *****************/}
      {!isWeb && (
        <BackComponent text='Create Group'>
          {body}
          <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-3'>
            <div className='border-b border-gray-200'></div>
            <div className='pt-5 pb-10 px-5'>
              <ButtonMobileComponent
                text='Continue'
                color='bg-custom-yellow'
                width='w-full'
                disabled={!selectedUsers?.size}
                onClick={handleContinue}
                iconPosition='right'
                icon={Arrow}
              />
            </div>
          </div>
        </BackComponent>
      )}
      {/***************** Web *****************/}
      {isWeb && (
        <WizardComponent btnText='Next' onCancel={() => navigate(-1)} onClick={handleContinue} title='Create Group'>
          {body}
        </WizardComponent>
      )}
    </>
  )
}

export default RoomCreateComponent
