import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import './index.css'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

const RadioComponent = (props: any): JSX.Element => {
  const { row, label, radios } = props ?? {}
  const children: JSX.Element = (
    <FormControl>
      {label ? <FormLabel>{label}</FormLabel> : <></>}
      <RadioGroup row={row}>
        {radios.map((v: any, i: number) => (
          <FormControlLabel
            key={i}
            control={<Radio />}
            label={v.label}
            disabled={v.disable ? true : false}
            checked={v.isChecked}
            onChange={v.onChange}
            //added
            data-testid={v.data_testid}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )

  return children
}

export default RadioComponent
