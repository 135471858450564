import Box from '@mui/material/Box'
import Logo from '../../images/cslogo.png'
import './index.css'

const CloseButtonComponent = (props: any): JSX.Element => {
  const { onClick } = props
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
        {/* Logo has a hyperlink if onClick is defined, otherwise simply show logo as image */}
        {onClick ? (
          <img
            src={Logo}
            width='210'
            height='40'
            onClick={onClick}
            style={{ cursor: 'pointer' }}
            alt='logo'
            title='Home'
          />
        ) : (
          <img src={Logo} width='210' height='40' alt='logo' />
        )}
      </Box>
    </Box>
  )
}

export default CloseButtonComponent
