import { useEffect } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import './index.css'
import { signoutSchoolAdmin } from 'utils/schools'

function Index(props: any): JSX.Element {
  const navigate: NavigateFunction = useNavigate()
  useEffect(() => {
    (async () => {
      await signoutSchoolAdmin()
      props.onSignOut()
      navigate('/')
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <></>
}

export default Index
