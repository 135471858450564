import CloseIcon from '@mui/icons-material/Close'
import DownloadIcon from '@mui/icons-material/Download'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@mui/material'
import Box from '@mui/material/Box'
import NavComponent from 'components/nav'
import TextComponent from 'components/text'
import React, { useState } from 'react'
import { baseURL } from 'utils/constants'

interface UploadButtonProps {
  isDisable: boolean
  onUpload: (file: File) => void
}

const FileUpload: React.FC<UploadButtonProps> = ({ isDisable, onUpload }) => {
  const [uploadOpen, setUploadOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isConsent, setIsConsent] = useState(false)

  // Function to handle uploading dialog open
  const handleDialogOpen = () => {
    setUploadOpen(true)
  }

  // Function to handle file selection
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      setSelectedFile(files[0])
    }
  }

  // Function to handle file upload
  const handleFileUpload = () => {
    if (selectedFile) {
      setUploadOpen(false) // Close the dialog after file upload clicked
      onUpload(selectedFile)
      setSelectedFile(null)
      setIsConsent(false)
    }
  }

  // Function to handle dialog close
  const handleDialogClose = () => {
    setSelectedFile(null)
    setUploadOpen(false)
    setIsConsent(false)
  }

  return (
    <div>
      {/* File Upload Button */}
      <Button
        disabled={isDisable}
        color='primary'
        onClick={handleDialogOpen}
        sx={{ fontSize: 13 }}
        data-testid='upload-button'
      >
        <FileUploadIcon sx={{ fontSize: 20 }} />
        &nbsp;Upload
      </Button>
      {/* Dialog for Selecting File and Starting Upload */}
      <Dialog open={uploadOpen} onClose={handleDialogClose}>
        <Button onClick={handleDialogClose} sx={{ position: 'absolute', top: '5%', right: 0 }}>
          <CloseIcon sx={{ fontSize: 20 }} />
        </Button>
        <DialogTitle sx={{ textAlign: 'center' }}>Choose File to Upload</DialogTitle>
        <DialogContent>
          <center>
            {/* Selected Filename */}
            {selectedFile?.name ? <DialogContentText>{selectedFile.name}</DialogContentText> : <></>}
            {/* Button to select file */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <input
                accept={'.csv'}
                id='contained-button-file'
                type='file'
                onChange={handleFileSelect}
                style={{ display: 'none' }}
              />
              <label htmlFor='contained-button-file'>
                <Button
                  variant='contained'
                  component='span'
                  color={selectedFile?.name ? 'primary' : 'secondary'}
                  sx={{ marginBottom: '15px' }}
                >
                  Browse
                </Button>
              </label>
            </Box>
            {selectedFile?.name ? (
              <div>
                {/* Checkbox for consent */}
                <FormControlLabel
                  control={
                    <Checkbox checked={isConsent} color='warning' onChange={(e) => setIsConsent(e.target.checked)} />
                  }
                  label={
                    <TextComponent size='body2'>
                      I confirm that I have the authority to share this parent data with Carpool School Inc. This
                      information will be governed by the Carpool School Inc.{' '}
                      <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
                        <NavComponent
                          href={`${baseURL}/home/index.html#privacy`}
                          text='Privacy Policy'
                          isOpenNewTab={true}
                        />
                      </span>
                      .
                    </TextComponent>
                  }
                  data-testid='consent-checkbox'
                />
                <Button
                  disabled={!isConsent}
                  onClick={handleFileUpload}
                  color={'secondary'}
                  variant='contained'
                  sx={{ display: 'flex', alignItems: 'center' }}
                  startIcon={<FileUploadIcon sx={{ fontSize: 20 }} />}
                  data-testid='upload-csv-button'
                >
                  Upload
                </Button>
              </div>
            ) : (
              <label htmlFor='contained-button-file2'>
                {/*******************DOWNLOAD SAMPLE CSV FILE********************/}
                <TextComponent size='body2'>Please make sure the uploaded file is in right format</TextComponent>
                <Button
                  variant='text'
                  data-testid='download-csv-button'
                  startIcon={<DownloadIcon />}
                  href='/sample_users.csv'
                  color='warning'
                  target='_blank'
                  rel='noopener noreferrer'
                  sx={{ marginTop: '6px' }}
                >
                  Download Sample CSV
                </Button>
              </label>
            )}
          </center>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default FileUpload
