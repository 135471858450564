import { LinearProgress } from '@mui/material'
import SelectMobileComponent from 'components/select-mobile'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { getSessionItem, removeSessionItem, setSessionItem } from 'utils/constants'
import { registerStudent } from 'utils/students'
import { MessageProps } from 'utils/types'
import { isValidScheduleGroup } from 'utils/validations'
import './index.css'

interface Props {
  userStudentRelationship?: string
  onAdd: (ssi: string, cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name, Admins, Code, Schedule Groups, User Relation and onAdd Callbck
  const { userStudentRelationship, onAdd } = props ?? {}
  const schoolName: string = getSessionItem('x-pool-sn') || ''
  const schoolCode: string = getSessionItem('x-pool-sc') || ''
  const schoolScheduleGroupNames: string[] = getSessionItem('x-pool-ssgn') || []

  // states
  const [selectedScheduleGroup, setSelectedScheduleGroup] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // handlers
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    // Save schedule group for following steps
    setSessionItem('x-pool-sg', selectedScheduleGroup)
    navigate('/add/school')
  }
  const onSubmit = async (): Promise<any> => {
    // Register Student
    setIsLoading(true)
    const { msg, data, error } = await registerStudent(
      schoolName,
      schoolCode,
      userStudentRelationship ?? '',
      selectedScheduleGroup,
      { address: '' },
      { address: '' },
    )
    if (error) setMsg(msg)
    else {
      // Clean up all saved items from add workflow
      removeSessionItem('x-pool-sn')
      removeSessionItem('x-pool-sa')
      removeSessionItem('x-pool-sc')
      removeSessionItem('x-pool-ssgn')
      // Return
      const id: string = (data?.studentId as string) ?? ''
      onAdd(id, () => {
        navigate(`/students?id=${id}`)
      })
    }
    setIsLoading(false)
  }

  const isValid: boolean = isValidScheduleGroup(schoolScheduleGroupNames, selectedScheduleGroup, true)
  const isFinalStep: boolean = userStudentRelationship ? true : false

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Wizard *****************/}
      <WizardComponent
        onBack={onBack}
        title={'Select School Schedule'}
        subtitle={`Choose one that matches your grade and dismissal time`}
        disable={!isValid}
        btnText={isFinalStep ? 'Save' : 'Next'}
        onClick={() => (isFinalStep ? onSubmit() : onNext())}
        data_testId={isFinalStep ? 'save-btn-testid' : 'next-btn-testid'}
      >
        <SelectMobileComponent
          data_testid='schedule_testid'
          isRequired={true}
          isError={!isValidScheduleGroup(schoolScheduleGroupNames, selectedScheduleGroup)}
          options={schoolScheduleGroupNames} // Options from the fetched data
          value={selectedScheduleGroup}
          onChange={(v: string) => setSelectedScheduleGroup(v)}
        />
      </WizardComponent>
    </>
  )
}

export default Index
