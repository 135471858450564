import InputMobileComponent from 'components/input-mobile'
import ToastComponent from 'components/toast'
import VerificationCodeInput from 'components/VerificationCodeInput'
import WizardComponent from 'components/wizard'
import { formatIncompletePhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import {
  defaultPhoneNumberCountry,
  passwordTooltip,
  phoneNumberRatesTooltip,
  phoneNumberTooltip,
  reenterPasswordTooltip,
} from 'utils/constants'
import { MessageProps } from 'utils/types'
import { forgotUserPassword, resetUserPassword } from 'utils/users'
import { isMatchingPassword, isValidCode, isValidPassword, isValidPhone } from 'utils/validations'
import './index.css'
import Box from '@mui/material/Box'

function Index(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()
  // user states
  const [vars, setVars] = useState<any>({
    step: 1,
    headerStatusMsg: {},
    userPhoneNumber: '',
    userCode: '',
    userPassword: '',
    userReenteredPassword: '',
  })
  const { step, headerStatusMsg, userPhoneNumber, userCode, userPassword, userReenteredPassword } = vars
  const [msg, setMsg] = useState<MessageProps>()

  // handlers
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    const { msg, error } = await forgotUserPassword(e164PhoneNumber)
    if (error) setMsg(msg)
    else setVars({ ...vars, step: 2 })
  }
  const onSubmit = async (): Promise<any> => {
    const { msg, error } = await resetUserPassword(e164PhoneNumber, userCode, userPassword)
    if (error) setMsg(msg)
    else navigate(`/signin`)
  }

  const formattedPhoneNumber: string = formatIncompletePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry)
  const e164PhoneNumber: string = isValidPhone(userPhoneNumber, true)
    ? parsePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry).format('E.164')
    : ''

  switch (step) {
    case 1:
      return (
        <WizardComponent
          onBack={onBack}
          title={'Reset Your Password'}
          subtitle={'Enter your phone number to receive a verification code'}
          disable={!isValidPhone(userPhoneNumber, true)}
          btnText={'Next'}
          onClick={onNext}
          data_testId={'next-btn-testid'}
        >
          <InputMobileComponent
            key='1a'
            type='tel'
            labeltext='Phone Number'
            isRequired={true}
            isError={!isValidPhone(userPhoneNumber)}
            helperText={
              !isValidPhone(userPhoneNumber)
                ? phoneNumberTooltip()
                : isValidPhone(userPhoneNumber, true)
                  ? phoneNumberRatesTooltip()
                  : ''
            }
            onChange={(v: string) => setVars({ ...vars, userPhoneNumber: v })}
            value={isValidPhone(userPhoneNumber) ? formattedPhoneNumber : userPhoneNumber}
          />
          {msg && (
            <ToastComponent
              style={msg?.style}
              heading={msg?.heading}
              text={msg?.text}
              onClose={() => setMsg(undefined)}
            />
          )}
        </WizardComponent>
      )
    case 2:
      return (
        <WizardComponent
          onBack={onBack}
          title={'Verify Your Phone Number'}
          subtitle={'Enter the verification code sent to your phone'}
          disable={!isValidCode(userCode)}
          btnText={'Next'}
          onClick={async () => setVars({ ...vars, step: 3 })}
        >
          <VerificationCodeInput key='2a' onChange={(code: string) => setVars({ ...vars, userCode: code })} />
          {msg && (
            <ToastComponent
              style={msg?.style}
              heading={msg?.heading}
              text={msg?.text}
              onClose={() => setMsg(undefined)}
            />
          )}
        </WizardComponent>
      )
    case 3:
      const isValid: boolean =
        isValidPassword(userPassword, true) && isMatchingPassword(userReenteredPassword, userPassword, true)
      return (
        <WizardComponent
          onBack={onBack}
          title={'Create a New Password'}
          subtitle={headerStatusMsg.text}
          disable={!isValid}
          btnText={'Save'}
          onClick={onSubmit}
        >
          <Box display='flex' flexDirection='column' justifyContent='center' paddingTop={2} gap={2}>
            <InputMobileComponent
              key='3a'
              type='Password'
              labeltext='Password'
              isRequired={true}
              isError={!isValidPassword(userPassword)}
              helperText={!isValidPassword(userPassword) ? passwordTooltip() : ''}
              onChange={(v: string) => {
                setVars({ ...vars, userPassword: v })
              }}
            />
            <InputMobileComponent
              key='3b'
              type='Password'
              labeltext='Confirm Password'
              isRequired={true}
              isError={!isMatchingPassword(userReenteredPassword, userPassword)}
              helperText={!isMatchingPassword(userReenteredPassword, userPassword) ? reenterPasswordTooltip() : ''}
              onChange={(v: string) => {
                setVars({ ...vars, userReenteredPassword: v })
              }}
            />
          </Box>
          {msg && (
            <ToastComponent
              style={msg?.style}
              heading={msg?.heading}
              text={msg?.text}
              onClose={() => setMsg(undefined)}
            />
          )}
        </WizardComponent>
      )
    default:
      return <></>
  }
}

export default Index
