// Sample events calendar build, explained and detailed over at
// https://justacoding.blog/react-calendar-component-example-with-events/

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import ButtonMobileComponent from 'components/button-mobile'
import { CalendarDateEditIcon } from 'components/icons'
import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import { useRef, useState } from 'react'
import { getFinalTime, getFullDate, getTimeNumber, getTimeString, getTimeStringFromNumber } from 'utils/constants'
import { CalendarEventProps, CalendarProps, CalendarState, DateTimeProps } from 'utils/types'
import './index.css'
dayjs.extend(customParseFormat)

// Interfaces
type CalendarNavigationProps = {
  date: Date
  setDate: (v: Date) => void
}

type CalendarGridProps = {
  date: Date
  events: CalendarEventProps[]
  onClick?: (v: CalendarProps[]) => Promise<void>
}

// Calendar Components
// Top bar, contains the month/year combo as well as back/forward links
const Navigation = ({ date, setDate }: CalendarNavigationProps) => {
  const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const currentDate = new Date() // Get the current date
  // Disable back button for past dates i.e. for dates older than current date
  const isBackDisabled =
    date.getFullYear() < currentDate.getFullYear() ||
    (date.getFullYear() === currentDate.getFullYear() && date.getMonth() <= currentDate.getMonth())

  return (
    <div className='navigation'>
      <div
        className={`back ${isBackDisabled ? 'disabled' : ''}`}
        data-testid='back-testid'
        onClick={() => {
          const newDate = new Date(date)
          newDate.setMonth(newDate.getMonth() - 1)
          setDate(newDate)
        }}
      >
        <ChevronLeftOutlinedIcon style={{ fontSize: 24 }} />
        {MONTHS[date.getMonth() === 0 ? 11 : date.getMonth() - 1]}
      </div>

      <div className='monthAndYear'>
        {MONTHS[date.getMonth()]} {date.getFullYear()}
      </div>

      <div
        className='forward'
        data-testid='forward-testid'
        onClick={() => {
          const newDate = new Date(date)
          newDate.setMonth(newDate.getMonth() + 1)
          setDate(newDate)
        }}
      >
        {MONTHS[date.getMonth() === 11 ? 0 : date.getMonth() + 1]}
        <ChevronRightOutlinedIcon style={{ fontSize: 24 }} />
      </div>
    </div>
  )
}

const DayLabels = (day: string, idx: number) => (
  <div key={idx} className='w-[20%] pb-3 pt-1 flex items-center justify-center text-sm p-0'>
    {day}
  </div>
)

// The grid of days, renders a month's worth of days and
// also populates the events on the relevant dates
const Grid = ({ date, events, onClick }: CalendarGridProps) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([]) // State for selected dates
  const [selectedSchedule, setSelectedSchedule] = useState<CalendarState | undefined>(CalendarState.ENABLE)
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>()
  const cellRefs = useRef<(HTMLDivElement | null)[]>([]) // Ref array to track calendar cells

  const scheduleOptions = [
    { label: 'Not Carpooling', state: CalendarState.DISABLE },
    { label: 'Set Pickup Time', state: CalendarState.ENABLE },
  ]
  const toStartOfDay = (date: Date): Date => {
    const newDate = new Date(date)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate
  }

  // Toggle date selection for multi-select functionality
  const toggleDateSelection = (date: Date, index: number) => {
    setSelectedDates((prevSelectedDates) => {
      const indexInArray = prevSelectedDates.findIndex((d) => d.getTime() === date.getTime())

      if (indexInArray === -1) {
        // Date is not in the array, so add it
        if (cellRefs.current[index] && prevSelectedDates.length === 0) {
          cellRefs.current[index]?.scrollIntoView({
            behavior: 'smooth', // Adds smooth scrolling
            block: 'start', // Scroll to end the selected cell
            inline: 'nearest',
          })
        }
        return [...prevSelectedDates, date]
      } else {
        // Date is in the array, so remove it
        return prevSelectedDates.filter((d) => d.getTime() !== date.getTime())
      }
    })
  }

  const findEventsForDate = (events: CalendarEventProps[], date: Date): CalendarEventProps[] => {
    return events.filter((event: CalendarEventProps) => date.getTime() === toStartOfDay(event.from).getTime())
  }
  // Finds the closest Monday relative to the first day of
  // the target month/year combination
  // Then increment upon this day until we have a full set
  // of date objects to work with
  const startingDate = new Date(date.getFullYear(), date.getMonth(), 1)
  startingDate.setDate(startingDate.getDate() - (startingDate.getDay() - 1))
  const monthsEvents: { date: Date; event: CalendarEventProps }[] = []
  const WEEKS_COUNT: number = 6,
    DAYS_COUNT: number = 7

  for (let i = 0; i < WEEKS_COUNT * DAYS_COUNT; i++) {
    const date = new Date(startingDate)
    const isWeekday = (): boolean => date.getDay() !== 0 && date.getDay() !== 6
    // skip saturday and sunday weekend days - use Mon-Fri 5 day labels with a width of 20% each
    if (isWeekday()) {
      monthsEvents.push({
        date,
        event: findEventsForDate(events, date)[0],
      })
    }
    startingDate.setDate(startingDate.getDate() + 1)
  }

  const currentDate: Date = toStartOfDay(new Date())

  return (
    <>
      {monthsEvents.map((v, i) => {
        const date: number = v.date.getDate()
        const { from, eventState } = v.event ?? {}
        const isPast = v.date.getTime() < currentDate.getTime()
        const isSelected = !isPast && selectedDates.some((d) => d.getTime() === from.getTime())
        // Cell can be in 4 states
        //  In the past - gray, disabled and not editable
        //  Actively being edited - green with edit icon
        //  Enabled/Custom Time Set for Carpool - orange with custom time and editable
        //  Disabled/No Carpool Required - red with X mark and editable
        //  Default - green with blank indicating school dismissal time for pickup and editable
        let cursor: string = 'pointer',
          cellBorderTopColor: string = 'green',
          cellBackgroundColor: string = 'transparent'
        let timeColor: string = 'green',
          time: string = ''
        let DateColor: string = 'black'

        if (isPast) {
          cursor = 'none'
          cellBorderTopColor = timeColor = '#99D196'
          DateColor = '#c1c1c1'
          time = ''
        } else if (isSelected) {
          cellBorderTopColor = timeColor = 'green'
          cellBackgroundColor = '#E3FCE3'
          time = getTimeString(getTimeNumber(from))
        } else if (eventState === CalendarState.ENABLE) {
          cellBorderTopColor = timeColor = '#FF7900'
          time = getTimeString(getTimeNumber(from))
        } else if (eventState === CalendarState.DISABLE) {
          cellBorderTopColor = timeColor = 'red'
          time = 'X'
        }
        // No onClick implies calendar is read only mode - disable cursor
        if (!onClick) cursor = 'none'

        return (
          <div
            key={i}
            ref={(el) => (cellRefs.current[i] = el)} // Assign ref to each calendar cell
            className={`w-[20%] h-[70px] p-0 space-y-1 px-2 flex flex-col items-center justify-start text-xs`}
            onClick={cursor === 'pointer' ? () => toggleDateSelection(from, i) : undefined}
            // style={{ background: `linear-gradient(90deg, ${cellBackgroundColor} 50%, transparent 50%)` }}
          >
            <div className='flex flex-col items-center w-[100%]' style={{ backgroundColor: cellBackgroundColor }}>
              {/* Top border of cell */}
              <div className={`w-[80%]`} style={{ borderTop: `2px solid ${cellBorderTopColor}`, cursor }} />
              {/* Date text */}
              <div style={{ color: DateColor, cursor, fontSize: '14px' }} className='py-2'>
                {date}
              </div>
              {/* Time */}
              <div style={{ color: timeColor, cursor, fontWeight: 500 }} data-testid='remain-testid'>
                {time}
              </div>
              {/* Conditionally rendered icon with reserved space */}
              <div className='flex items-center justify-center'>
                {isSelected && <CalendarDateEditIcon color='green' />}
              </div>
            </div>
          </div>
        )
      })}
      {onClick && selectedDates && selectedDates?.length > 0 && (
        <>
          {/* Set Time */}
          <div className='flex justify-around bg-light-yellow rounded-2xl border border-2 border-custom-yellow my-4'>
            {scheduleOptions.map(({ label, state }) => (
              <div
                key={state}
                onClick={() => setSelectedSchedule(state)}
                className={`inline-block text-sm rounded-xl px-3 py-2 cursor-pointer flex-1 text-center
                  ${selectedSchedule === state ? 'bg-custom-yellow text-gray-800' : 'bg-light-yellow text-gray-400'}`}
                style={{
                  width: '150px',
                }}
              >
                {label}
              </div>
            ))}
          </div>

          <div className='w-full flex items-center gap-5 mt-4'>
            {selectedSchedule === CalendarState.ENABLE && (
              <TimePicker
                value={dayjs(selectedDates[0])}
                onChange={(d: Dayjs | null) => setSelectedTime(d)}
                slotProps={{
                  textField: {
                    size: 'small',
                    variant: 'outlined',
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#e5e5e5', // Apply inline custom yellow color
                          borderRadius: '8px', // Adjust the border-radius as needed
                        },
                        '&:hover fieldset': {
                          borderColor: '#e5e5e5', // Maintain border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#e5e5e5', // Maintain border color on focus
                          borderWidth: '1px', // FIXME: Purposely disable default thickening of border on focus until we can do the same on other filters
                        },
                      },
                      width: '150px',
                    },
                    inputProps: {
                      readOnly: true,
                      style: { fontSize: '1rem' },
                    },
                  },
                }}
              />
            )}

            <ButtonMobileComponent
              text='Save'
              width='w-[130px]'
              onClick={() => {
                if (selectedSchedule) {
                  // Extract hours, minutes, and seconds from the selected time
                  const hours = selectedTime?.hour() ?? selectedDates[0].getHours()
                  const minutes = selectedTime?.minute() ?? selectedDates[0].getMinutes()
                  const seconds = selectedTime?.second() ?? selectedDates[0].getSeconds()

                  const payload = selectedDates.map((d: Date) => {
                    const updatedDate = new Date(d)
                    updatedDate.setHours(hours)
                    updatedDate.setMinutes(minutes)
                    updatedDate.setSeconds(seconds)

                    return { from: updatedDate, userAction: selectedSchedule }
                  })

                  onClick(payload).catch((e: Error) => console.error(e))
                } else if (selectedSchedule === CalendarState.DISABLE) {
                  const payload = selectedDates.map((d: Date) => ({ from: d, userAction: selectedSchedule }))
                  onClick(payload).catch((e: Error) => console.error(e))
                }
                // reset state
                setSelectedDates([])
                setSelectedSchedule(CalendarState.ENABLE)
                setSelectedTime(null)
              }}
            />
          </div>
        </>
      )}
    </>
  )
}

export const CalendarComponent = (props: any) => {
  const events: CalendarEventProps[] = props.events

  const [date, setDate] = useState<Date>(props?.date ?? new Date())

  const handleClick = async (event: CalendarProps[]): Promise<void> => {
    await props.onChange(event)
  }

  return (
    <div className='calendar' data-testid='dates-testid'>
      <Navigation date={date} setDate={setDate} />

      {['Mo', 'Tu', 'We', 'Th', 'Fr'].map(DayLabels)}

      <Grid date={date} events={events} onClick={props?.onChange ? handleClick : undefined} />
    </div>
  )
}

export const toCalendarEvents = (schoolEndTime: number, dateTime: DateTimeProps, defaultTime?: number | null) => {
  const events: CalendarEventProps[] = []
  const startDate: string = new Date().toISOString() // Initialize start to current date.
  const endDate: string = new Date(new Date().getFullYear() + 1, 11, 31).toISOString() // Initialize end to end of next year.
  let loop = new Date(new Date(startDate))
  do {
    const d: string = getFullDate(loop)
    const t: number = getFinalTime(schoolEndTime, dateTime[d], defaultTime)
    const event: CalendarEventProps | undefined = {
      from: new Date(`${d}T${getTimeStringFromNumber(t === -1 ? schoolEndTime : t)}`),
      eventState: t === -1 ? CalendarState.DISABLE : d in dateTime ? CalendarState.ENABLE : undefined,
    }
    const isWeekday = (): boolean => event.from.getDay() !== 0 && event.from.getDay() !== 6
    if (isWeekday()) {
      if (event) events.push(event)
    }
    loop = new Date(loop.setDate(loop.getDate() + 1))
  } while (loop <= new Date(endDate))
  return events
}
