import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import GridViewIcon from '@mui/icons-material/GridView'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { LinearProgress } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ButtonMenuComponent from 'components/button-menu'
import {
  ArrowIcon,
  ChangePasswordIcon,
  ChangeSchoolIcon,
  DefaultProfileIcon,
  DeleteAccountIcon,
  LogOutIcon,
  MessagingIcon,
  SettingsIcon,
  TermsAndConditionsIcon,
} from 'components/icons'
import ModalConfirmComponent from 'components/modal-confirm'
import ToastComponent from 'components/toast'
import { Fragment, useEffect, useRef, useState } from 'react'
import { NavigateFunction, Outlet, useNavigate } from 'react-router-dom'
import { baseURL, setItem, titleCaseSchoolNameForUI } from 'utils/constants'
import { MessageProps, StudentProps } from 'utils/types'
import { deleteUser } from 'utils/users'
import Logo from '../../images/cslogo.png'
import './index.css'
import AvatarComponent from 'components/avatar'
import { UserOutputProps } from 'types/UserTypes'

export interface NavProps {
  icon?: JSX.Element
  item: string
  href: string
  itemProps?: { [key: string]: any }
}

interface NavData {
  phoneNumber: string
  studentCount: number
}

interface AppBarProps extends MuiAppBarProps {
  isDrawerOpen?: boolean | null // tertiary state to distinguish between drawer open|closed|not_shown
}

interface AppBarMenuProps {
  isOpen: boolean
  menuButton: JSX.Element
  menuData?: NavData
  menuLinks: NavProps[][]
  signOutLink?: NavProps
  onOpen: () => void
  onClose: () => void
  data_testid?: string
}

const drawerOpenWidthXS: number = 140
const drawerOpenWidthUpSM: number = 210
const drawerCloseWidthXS: number = 50
const drawerCloseWidthUpSM: number = 60
const color: any = 'info'

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen',
})<AppBarProps>(({ theme, isDrawerOpen }) => ({
  width: `calc(100% - ${isDrawerOpen === null ? 0 : drawerCloseWidthXS}px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${isDrawerOpen === null ? 0 : drawerCloseWidthUpSM}px)`,
  },

  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(isDrawerOpen && {
    marginLeft: drawerOpenWidthXS,
    width: `calc(100% - ${drawerOpenWidthXS}px)`,
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerOpenWidthUpSM,
      width: `calc(100% - ${drawerOpenWidthUpSM}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export const AppBarMenu: React.FC<AppBarMenuProps> = (props: AppBarMenuProps) => {
  const navigate: NavigateFunction = useNavigate()

  // INPUTS: Name, Relationship
  const { isOpen, menuButton, menuData, menuLinks, signOutLink, onOpen, onClose, data_testid } = props
  const { phoneNumber, studentCount } = menuData ?? {}

  // states
  const panelRef = useRef<HTMLDivElement>(null)
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false)
  const [delOpen, setDelOpen] = useState<boolean>(false)
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // handlers
  const handleNavigate = (url: string) => {
    onClose()
    navigate(url)
  }
  const handleToggleSettings = () => setIsSettingsOpen(!isSettingsOpen)
  const handleDelClick = () => setDelOpen(true)
  const handleDelClose = () => setDelOpen(false)
  const handleDelSubmit = async () => {
    setDelOpen(false)
    setIsLoading('Deleting Family Users Account')
    const { msg, error } = await deleteUser(phoneNumber ?? '')
    setIsLoading('')
    if (error) setMsg(msg)
    // even if API resturned an error response- backend may have purged user
    // so clear session and go to homepage
    setItem('tokens', null)
    handleNavigate('/')
  }

  //hooks
  useEffect(() => {
    if (isOpen) {
      const handleOutsideInteraction = (event: MouseEvent | TouchEvent) => {
        const target = event.target as Node
        if (panelRef.current && !panelRef.current.contains(target) && !delOpen) {
          onClose()
        }
      }
      document.addEventListener('mousedown', handleOutsideInteraction)
      document.addEventListener('touchstart', handleOutsideInteraction)
      return () => {
        document.removeEventListener('mousedown', handleOutsideInteraction)
        document.removeEventListener('touchstart', handleOutsideInteraction)
      }
    }
  }, [isOpen, delOpen, onClose, panelRef])

  if (!menuButton) return <></>

  return (
    <div ref={panelRef} data-testid={data_testid} className='relative'>
      <button className='flex items-center' onClick={isOpen ? onClose : onOpen}>
        {menuButton}
      </button>
      {/* App Bar Menu Button */}
      {isOpen && (
        <div className='dropdown absolute top-full right-0 mt-2 min-w-[200px] flex flex-col items-start  pb-2 overflow-y-auto bg-white rounded-lg shadow-lg border border-gray-300 z-10'>
          {/***************** Display progress and error *****************/}
          {isLoading && <LinearProgress />}
          {msg && (
            <ToastComponent
              style={msg?.style}
              heading={msg?.heading}
              text={msg?.text}
              onClose={() => setMsg(undefined)}
            />
          )}
          {/***************** Menu *****************/}
          {menuData ? (
            // App Bar Menu Links built with Menu Data - Used by Family Menu
            <>
              <ButtonMenuComponent
                name='Edit Profile'
                data_testid='edit-profile-testid'
                leftIcon={<DefaultProfileIcon width={9} height={9} />}
                onClick={() => handleNavigate('/profile')}
              />
              <div className='h-0.5 w-[90%] bg-custom-gray mx-auto'></div>
              <ButtonMenuComponent
                name='Messaging (Beta)'
                leftIcon={<MessagingIcon />}
                onClick={() => handleNavigate('/messaging')}
                data_testid={'messaging-testid'}
              />
              <ButtonMenuComponent
                name='Change School'
                leftIcon={<ChangeSchoolIcon />}
                onClick={() => handleNavigate('/change')}
                data_testid={'change-school-testid'}
              />
              <ButtonMenuComponent
                name='Settings'
                style={{
                  backgroundColor: '#FFFDF6',
                }}
                leftIcon={<SettingsIcon />}
                rightIcon={<ArrowIcon isOpen={isSettingsOpen} />}
                onClick={handleToggleSettings}
              />
              {isSettingsOpen && (
                <div
                  className={`w-full pl-6  bg-warm-white overflow-hidden transition-all duration-300 ease-in-out max-h-60`}
                >
                  {/* <ButtonMenuComponent name='Subscription' leftIcon={<SubscriptionIcon />} /> */}
                  <ButtonMenuComponent
                    name='Change Password'
                    leftIcon={<ChangePasswordIcon />}
                    onClick={() => handleNavigate('/settings')}
                  />
                  {/* <ButtonMenuComponent name='Notification' leftIcon={<NotificationIcon />} /> */}
                  <ButtonMenuComponent
                    name='Terms & Conditions'
                    onClick={() => handleNavigate(`${baseURL}/home/index.html#legal`)}
                    leftIcon={<TermsAndConditionsIcon />}
                  />
                  <ButtonMenuComponent
                    name='Delete Account'
                    leftIcon={<DeleteAccountIcon />}
                    onClick={handleDelClick}
                  />
                </div>
              )}
              <ButtonMenuComponent
                name='Sign out'
                leftIcon={<LogOutIcon />}
                onClick={() => handleNavigate('/signout')}
                data_testid={'logout-testid'}
              />
            </>
          ) : (
            // App Bar Menu Links built with Menu Links - Used by Admin Menu
            menuLinks.map((linkGroup: any, groupIndex: any) => (
              <div
                key={groupIndex}
                onClick={() => {
                  onClose() // Close menu when item is clicked
                }}
                className='flex flex-col items-start flex-1 self-stretch'
              >
                {linkGroup.map((link: any, linkIndex: any) => (
                  // FIXME: Entire row needs to be clickable and not just the name
                  <ButtonMenuComponent
                    key={linkIndex}
                    name={link.item}
                    leftIcon={link.icon}
                    onClick={() => handleNavigate(link.href)}
                  />
                ))}
                {signOutLink && (
                  <>
                    <hr className='w-full border-t border-gray-300 my-2' />
                    <ButtonMenuComponent
                      name={signOutLink.item}
                      leftIcon={signOutLink.icon}
                      onClick={() => handleNavigate(signOutLink.href)}
                    />
                  </>
                )}
              </div>
            ))
          )}
          {/***************** Delete Modal *****************/}
          <ModalConfirmComponent
            isShow={delOpen}
            title={studentCount ? 'Account Deletion Blocked' : 'Delete Account?'}
            body={
              studentCount
                ? "You can't delete your account while registered with schools. Please remove school affiliations to delete your account. For help, contact support@carpool.school."
                : 'You will lose access to this account. This action cannot be undone.'
            }
            actionIcon={studentCount ? 'redBlockIcon' : 'redInfoIcon'}
            onClose={studentCount ? handleDelClose : undefined}
            onActionCancel={studentCount ? undefined : handleDelClose}
            onActionSubmit={studentCount ? undefined : handleDelSubmit}
          />
        </div>
      )}
    </div>
  )
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: drawerOpenWidthXS,
    [theme.breakpoints.up('sm')]: {
      width: drawerOpenWidthUpSM,
    },

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: drawerCloseWidthXS,
      [theme.breakpoints.up('sm')]: {
        width: drawerCloseWidthUpSM,
      },
    }),
  },
}))

const Nav = (props: {
  brand: NavProps
  title?: string
  menu?: JSX.Element
  data?: NavData
  links?: NavProps[][]
  signOutLink?: NavProps
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // for mobile screens
  // const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg')); // for tablet screens

  // inputs
  const { brand, title, menu, data, links, signOutLink } = props ?? {}
  const isShowAppBar: boolean = true // Always show app bar
  const isShowDrawer: boolean = Array.isArray(links) ? false : false // Never show drawer

  // states
  const [isAppBarMenuOpen, setIsAppBarMenuOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  // handlers
  const handleOpenAppBarMenu = () => setIsAppBarMenuOpen(true)
  const handleCloseAppBarMenu = () => setIsAppBarMenuOpen(false)
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen)

  return (
    <div className='flex'>
      {/* FIXME: Ensure app bar is responsive on smaller screens especially with long title */}
      {isShowAppBar && (
        <AppBar
          position='absolute'
          isDrawerOpen={isShowDrawer ? isDrawerOpen : null}
          sx={{ display: 'block', background: '#ffffff', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
        >
          <Toolbar>
            {/* Brand Logo */}
            <Typography
              component='a'
              variant='h6'
              href={brand.href}
              color='inherit'
              noWrap
              sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                textDecoration: 'none',
                cursor: 'none', // Disable the cursor
                pointerEvents: 'none', // Disable clicking
              }}
            >
              <img
                src={Logo}
                width='200'
                height='40'
                style={{
                  cursor: 'pointer', // Enable the cursor on the image
                  pointerEvents: 'auto', // Allow click events on the image
                  transition: 'opacity 0.3s', // Smooth transition for hover effect
                }}
                alt='Carpool.School Logo and Brand'
                onMouseOver={(e) => (e.currentTarget.style.opacity = '0.8')} // Change opacity on hover
                onMouseOut={(e) => (e.currentTarget.style.opacity = '1')} // Reset opacity
              />
              {title && <span className='text-disable text-base ml-4'>{title}</span>}
            </Typography>
            {/* AppBarMenu */}
            {menu && (
              <AppBarMenu
                isOpen={isAppBarMenuOpen}
                menuButton={menu}
                menuData={data}
                menuLinks={links ?? []}
                signOutLink={signOutLink}
                onOpen={handleOpenAppBarMenu}
                onClose={handleCloseAppBarMenu}
                data_testid='nav-icon-testid'
              />
            )}
          </Toolbar>
        </AppBar>
      )}
      {/* FIXME: Ensure children element accounts for drawer width */}
      {isShowDrawer && (
        <Drawer data-testid='sidebar-nav' variant='permanent' open={isDrawerOpen}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingTop: 'env(safe-area-inset-top)',
              paddingBottom: 'env(safe-area-inset-bottom)',
            }}
          >
            {isDrawerOpen ? (
              <>
                {isMobile ? (
                  <Typography variant='subtitle1' sx={{ fontWeight: 500 }}>
                    Carpool.School
                  </Typography>
                ) : (
                  <Typography variant='caption' sx={{ fontWeight: 500 }}>
                    Carpool.School
                  </Typography>
                )}
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftOutlinedIcon />
                </IconButton>
              </>
            ) : (
              <IconButton edge='start' color='inherit' aria-label='open drawer' onClick={toggleDrawer}>
                <MenuOutlinedIcon color={color} />
              </IconButton>
            )}
          </Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <List
              component='nav'
              sx={{
                '& .MuiListItemButton-root:hover': {
                  bgcolor: '#fcf7de',
                  borderRadius: '10px',
                  '&, & .MuiListItemIcon-root': {
                    color: 'primary',
                  },
                },
                marginX: { xs: '8px', sm: '10px', md: '5px' },
              }}
            >
              <center>
                {links?.map((va, i) => (
                  <Fragment key={i}>
                    {va.map((v, j) => (
                      <ListItemButton
                        key={j}
                        href={v.href}
                        disableGutters
                        selected={window.location.pathname.endsWith(v.href)}
                        {...v?.itemProps}
                        sx={{
                          '&.Mui-selected': {
                            backgroundColor: '#fcf7de',
                            borderRadius: '10px',
                          },
                          px: { xs: 0.7, sm: 0.7, md: 1.5 },
                          py: 1.1,
                          my: 0.5,
                          borderRadius: '10px',
                          '& .MuiListItemIcon-root': {
                            minWidth: { xs: 40, sm: 50 }, // Adjust icon size for small screens
                          },
                          '& svg': {
                            fontSize: { xs: '20px', sm: '24px' }, // Adjust svg icon size for small screens
                          },
                        }}
                      >
                        {v.icon && (
                          <Tooltip title={v.item}>
                            <ListItemIcon>{v.icon}</ListItemIcon>
                          </Tooltip>
                        )}
                        <ListItemText
                          primary={v.item}
                          primaryTypographyProps={{
                            fontSize: { xs: '12px', sm: '16px' }, // Adjust font size for small screens
                          }}
                        />
                      </ListItemButton>
                    ))}
                  </Fragment>
                ))}
              </center>
            </List>
          </Box>
          {signOutLink && (
            <List
              component='nav'
              sx={{
                marginX: '5px',
                '& .MuiListItemButton-root:hover': {
                  bgcolor: '#fcf7de',
                  borderRadius: '10px',
                  '&, & .MuiListItemIcon-root': {
                    color: 'primary',
                  },
                },
              }}
            >
              <center>
                <ListItemButton
                  href={signOutLink.href}
                  disableGutters
                  sx={{
                    borderRadius: '10px',
                    px: { xs: 0.7, sm: 0.7, md: 1.5 },
                    py: 1.1,
                    my: 0.5,
                    '& .MuiListItemIcon-root': {
                      minWidth: { xs: 40, sm: 50 }, // Adjust icon size for small screens
                    },
                    '& svg': {
                      fontSize: { xs: '20px', sm: '24px' }, // Adjust svg icon size for small screens
                    },
                    marginX: { xs: '8px', sm: '10px', md: '5px' },
                  }}
                >
                  <Tooltip title={signOutLink.item}>
                    <ListItemIcon>{signOutLink.icon}</ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={signOutLink.item}
                    primaryTypographyProps={{
                      fontSize: { xs: '12px', sm: '16px' }, // Adjust font size for small screens
                    }}
                  />
                </ListItemButton>
              </center>
            </List>
          )}
        </Drawer>
      )}
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        <Box
          sx={{
            // Provide a top margin to account for appbar height, and nominal bottom margin
            // There is no side panel or drawer on the side. So, provide a nominal padding
            ...(isShowAppBar && { mt: isMobile ? 8 : 9, mb: 2, px: 2, py: 0 }),
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </div>
  )
}

interface TopAppBarComponentProps {
  appBarColor: string
  backgroundColor: string
  iconElement?: JSX.Element
  leftElement?: JSX.Element
  rightElement?: JSX.Element
  children?: React.ReactNode
}

export const TopAppBarComponent = (props: TopAppBarComponentProps): JSX.Element => {
  // const theme = useTheme()
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // for mobile screens
  // const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg')); // for tablet screens
  const { appBarColor, backgroundColor, iconElement, leftElement, rightElement, children } = props ?? {}

  return (
    <div
      style={{
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        minHeight: '100vh', // Ensures full height layout
      }}
    >
      <AppBar
        position='fixed'
        isDrawerOpen={null}
        sx={{
          display: 'block',
          background: appBarColor,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          paddingTop: 'env(safe-area-inset-top)', // Add safe area padding here
        }}
      >
        <Toolbar>
          <div className={`flex w-full justify-between items-center`}>
            <div className='flex flex-grow items-center gap-2.5 flex-1'>
              {iconElement}
              {leftElement}
            </div>
            {rightElement}
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component='main'
        sx={{
          backgroundColor,
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          paddingTop: '64px', // Account for the AppBar height
        }}
      >
        <Box sx={{ mt: 2, mb: 2, px: 0, py: 0 }}>{children}</Box>
      </Box>
    </div>
  )
}

export const WithoutNav = (): JSX.Element => {
  return (
    <div
      style={{
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
      }}
    >
      <Outlet />
    </div>
  )
}

export const WithNav = () => {
  // brand
  const navBrand: NavProps = { item: 'Carpool.School', href: '/' }

  return <Nav brand={navBrand} />
}

export const WithFamilyNav = ({
  isSignedIn,
  students,
  user,
  userPhoto,
}: {
  isSignedIn: boolean
  students: StudentProps[]
  user?: UserOutputProps
  userPhoto: string
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // for mobile screens

  // brand
  const brand: NavProps = { item: 'Carpool.School', href: '/' }

  // menu
  const menu: JSX.Element | undefined =
    isSignedIn && user?.name ? (
      <>
        <AvatarComponent photoLink={userPhoto} defaultElement={<DefaultProfileIcon width={9} height={9} />} />
        {!isMobile && <span className='text-headings font-semibold text-sm mx-2.5'>{user?.name.split(' ')[0]}</span>}
      </>
    ) : undefined

  // data
  const studentCount: number = students && students?.length > 0 ? students.length : 0
  const data: NavData = { phoneNumber: user?.phoneNumber ?? '', studentCount }

  // links
  // const links: NavProps[][] = []
  // const isStudents: boolean = Array.isArray(students) && students.length ? true : false
  // if (isSignedIn) {
  //   if (!isStudents) {
  //     links.push([
  //       { icon: <DashboardOutlined color={color} />, item: 'Dashboard', href: '/' },
  //       { icon: <ManageAccountsOutlinedIcon color={color} />, item: 'Profile', href: '/profile' },
  //       { icon: <SettingsOutlinedIcon color={color} />, item: 'Settings', href: '/settings' },
  //     ])
  //   } else {
  //     const query: string = props?.currentStudentId ? `?id=${props.currentStudentId}` : ''
  //     links.push([
  //       { icon: <DashboardOutlined color={color} />, item: 'Dashboard', href: `/students${query}` },
  //       { icon: <LightModeOutlinedIcon color={color} />, item: 'Morning', href: `/morning${query}` },
  //       { icon: <LightModeIcon color={color} />, item: 'Afternoon', href: `/afternoon${query}` },
  //       { icon: <SchoolOutlinedIcon color={color} />, item: 'Schools', href: `/change${query}` },
  //       { icon: <ManageAccountsOutlinedIcon color={color} />, item: 'Profile', href: '/profile' },
  //       { icon: <SettingsOutlinedIcon color={color} />, item: 'Settings', href: '/settings' },
  //     ])
  //   }
  // }

  // signout link
  // const signOutLink: NavProps | undefined = isSignedIn
  //   ? { icon: <LogoutOutlinedIcon color={color} />, item: 'Sign out', href: '/signout' }
  //   : undefined

  return <Nav brand={brand} menu={menu} data={data} /*links={links} signOutLink={signOutLink}*/ />
}

export const WithAdminNav = (props: any) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // for mobile screens

  const { isSignedIn, isActive, schoolName } = props ?? {}

  // brand
  const brand: NavProps = { item: 'Carpool.School', href: !isSignedIn ? '/' : '/admin/settings' }

  // menu
  const menu: JSX.Element | undefined =
    isSignedIn && schoolName ? (
      <>
        <SettingsOutlinedIcon color={color} />
        {!isMobile && (
          <span className='text-headings font-semibold text-sm mx-2.5'>{titleCaseSchoolNameForUI(schoolName)}</span>
        )}
      </>
    ) : undefined

  // links
  const links: NavProps[][] = []
  if (isSignedIn) {
    if (!isActive)
      links.push([{ icon: <SettingsOutlinedIcon color={color} />, item: 'Settings', href: '/admin/settings' }])
    else
      links.push([
        { icon: <GridViewIcon color={color} />, item: 'Dashboard', href: '/admin/dashboard' },
        { icon: <PeopleOutlinedIcon color={color} />, item: 'Families', href: '/admin/users' },
        { icon: <SettingsOutlinedIcon color={color} />, item: 'Settings', href: '/admin/settings' },
      ])
  }

  // signout link
  const signOutLink: NavProps | undefined = isSignedIn
    ? { icon: <LogoutOutlinedIcon color={color} />, item: 'Sign out', href: '/admin/signout' }
    : undefined

  return <Nav brand={brand} menu={menu} links={links} signOutLink={signOutLink} />
}
