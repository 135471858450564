import { CSSProperties } from 'react'

interface ButtonMenuComponentProps {
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  name: string
  style?: CSSProperties
  data_testid?: string
  onClick: () => void
}

const ButtonMenuComponent = (props: ButtonMenuComponentProps): JSX.Element => {
  const { leftIcon, rightIcon, name, onClick, style, data_testid } = props ?? {}

  return (
    <div
      className='flex items-center gap-[6px] self-stretch px-[20px] py-[12px] text-headings w-full hover:bg-gray-100'
      data-testid={data_testid}
      style={style}
      onClick={async () => {
        if (onClick) await onClick()
      }}
    >
      {leftIcon && <div>{leftIcon}</div>}
      <div className='text-headings text-sm font-figtree text-[14px] font-medium leading-[20px] flex-grow cursor-pointer'>
        {name}
      </div>
      {rightIcon && <div>{rightIcon}</div>}
    </div>
  )
}

export default ButtonMenuComponent
