import React from 'react'
import './index.css'

interface HorizontalScrollContainerComponentProps {
  children: React.ReactNode
  className?: string // Allow additional custom classes
  style?: React.CSSProperties // Allow custom inline styles
}

const HorizontalScrollContainerComponent: React.FC<HorizontalScrollContainerComponentProps> = ({
  children,
  className = '',
  style = {},
}) => {
  return (
    <div
      className={`horizontal-scroll flex flex-nowrap overflow-x-auto overflow-y-hidden whitespace-nowrap gap-2 ${className}`}
      style={{
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        ...style, // Merge with custom styles
      }}
    >
      {children}
    </div>
  )
}

export default HorizontalScrollContainerComponent
