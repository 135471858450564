import './index.css'
const BoxMobileComponent = (props: any): JSX.Element => {
  const { name, tagline, bgColor, icon, borderColor, data_testid } = props ?? {}

  return (
    <div
      className={`flex flex-col items-start gap-3 py-6 rounded-xl container`}
      style={{ backgroundColor: bgColor }}
      data-testid={data_testid}
    >
      <div
        className={`flex p-1 items-center gap-2.5 rounded-xl bg-[#FFF] backdrop-blur-[calc(var(--sds-size-blur-100)/2)]`}
        dangerouslySetInnerHTML={{ __html: icon }}
        style={{ border: `1px solid ${borderColor}` }}
      ></div>
      <div className='flex flex-col items-start gap-1.5 self-stretch'>
        <div className='text-[#474747] font-semibold leading-normal name '>{name}</div>
        <div className='text-[#9e9e9e] font-normal leading-[20px] tagline'>{tagline}</div>
      </div>
    </div>
  )
}

export default BoxMobileComponent
