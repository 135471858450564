import { LinearProgress } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import AvatarComponent from 'components/avatar'
import BackComponent from 'components/back'
import ButtonMobileComponent from 'components/button-mobile'
import { CalendarComponent, toCalendarEvents } from 'components/calendar'
import {
  CommentIcon,
  CopyIcon,
  LineIcon,
  MessagingDotIcon,
  ScheduleGroupIcon,
  ScheduleIcon,
  SeatIcon,
  TimeIcon,
  UserBioIcon,
} from 'components/icons'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import facebook from 'images/facebook.png'
import instagram from 'images/instagram.png'
import linkedin from 'images/linkedin.png'
import { parsePhoneNumber } from 'libphonenumber-js/max'
import React, { useEffect, useState } from 'react'
import { NavigateFunction, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { RoomType } from 'types/RoomTypes'
import { UserPoolOutputProps } from 'types/UserTypes'
import { getFinalTime, getTimeString, isWeb, METERS_TO_MILES, parseJwt } from 'utils/constants'
import { createRoom } from 'utils/rooms'
import { CalendarEventProps, MessageProps, ScheduleGroupProps } from 'utils/types'
import { getUserPool } from 'utils/users'
import './index.css'

interface Props {
  tokens: any
  onUpdate: (cb?: () => void) => void
}

const TruncatedText = ({
  text,
  maxChars = 150, // Default to 150 if not provided
}: {
  text: string
  maxChars?: number
}) => {
  const [isExpanded, setIsExpanded] = useState(false) // Tracks whether the text is expanded

  const toggleTextState = () => setIsExpanded(true) // Expand to full text on click

  const isTruncated = text.length > maxChars

  const displayText = isExpanded ? text : text.slice(0, maxChars)

  return (
    <p style={{ display: 'inline' }}>
      {displayText.split('\n').map((line, index, arr) => (
        <React.Fragment key={index}>
          {line}
          {index < arr.length - 1 && <br />}
        </React.Fragment>
      ))}
      {isTruncated && !isExpanded && (
        <span>
          {'... '}
          <button
            onClick={toggleTextState}
            className='text-primary hover:underline text-sm font-medium inline'
            style={{ display: 'inline', whiteSpace: 'nowrap' }}
          >
            More
          </button>
        </span>
      )}
    </p>
  )
}

function Index(props: Props): JSX.Element {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigate: NavigateFunction = useNavigate()
  const location = useLocation()

  const userId: string = location.state?.userPhoneNumber ?? ''
  const comId: string = params?.comId ?? ''
  const userStudentId = searchParams.get('userStudentId') ?? ''
  const studentId = searchParams.get('studentId') ?? ''
  const poolDirection = searchParams.get('poolDirection') ?? ''
  const poolDate = searchParams.get('poolDate') ?? ''

  // INPUTS
  const { tokens, onUpdate } = props
  const { IdToken } = tokens
  const phoneNumber: string = parseJwt(IdToken).phone_number

  // STATES
  const [userPool, setUserPool] = useState<UserPoolOutputProps>()
  const [tooltipText, setTooltipText] = useState('Copy to clipboard')
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // HANDLERS
  const handleCopy = () => {
    navigator.clipboard.writeText(contact).then(
      () => {
        setTooltipText('Copied!')
        setTimeout(() => {
          setTooltipText('Copy to clipboard')
        }, 3000) // Reset the tooltip after 2 seconds
      },
      () => {
        setTooltipText('Failed to copy')
        setTimeout(() => {
          setTooltipText('Copy to clipboard')
        }, 3000) // Reset the tooltip after 2 seconds
      },
    )
  }
  const handleMessage = async (roomId?: string) => {
    if (roomId) navigate(`/messaging/${roomId}`)
    else {
      // Create private chat room
      const { msg, data, error } = await createRoom(RoomType.PRIVATE, [userId, phoneNumber])
      if (error) setMsg(msg)
      else if (data) {
        onUpdate(() => navigate(`/messaging/${data.roomId}`))
      }
    }
  }

  // HOOKS
  useEffect(() => {
    (async function () {
      setIsLoading('Loading user...')
      const res = await getUserPool(userId, comId, userStudentId, studentId)
      if (res.error) setMsg(res.msg)
      else if (res.data) {
        setUserPool(res.data.userPool)
      }
      setIsLoading('')
    })()
  }, [userId, comId, userStudentId, studentId, navigate])

  if (!userPool) return <LinearProgress />

  // REUSABLE JSX ELEMENTS
  const {
    // School Profile
    schoolScheduleGroups,

    // User Profile
    userName,
    userBio,
    userPhotoLink = '',
    userSocialLinks,

    // Student Pool
    scheduleGroupName,
    studentActivities,
    userRelationship,
    // userLastSeen,

    // Pickup Details
    pickupDistance,
    // pickupVehicle,
    pickupSeatsFilled,
    pickupSeatsAvailable,
    pickupComments = '',

    // Dropoff Details
    dropoffDistance,
    // dropoffVehicle,
    dropoffSeatsFilled,
    dropoffSeatsAvailable,
    dropoffComments = '',
    dropoffTimeDefault,
    dropoffTimes,

    // Messaging Details
    roomId,
  } = userPool

  // Parse E.164 user phone number to national format
  const contact: string = parsePhoneNumber(userId).formatNational()
  // Build photo link
  const photoLink: string = userPhotoLink ?? ''
  // Destructure each social link
  const facebookLink: string | undefined = userSocialLinks?.facebook
  const instagramLink: string | undefined = userSocialLinks?.instagram
  const linkedinLink: string | undefined = userSocialLinks?.linkedin
  const activities = studentActivities
  // Use pool direction to select between dropoff and pickup distance
  const distance: number | undefined =
    poolDirection === 'pickup'
      ? pickupDistance
      : poolDirection === 'dropoff'
        ? dropoffDistance
        : (dropoffDistance ?? pickupDistance)
  // Use pool direction to select between dropoff and pickup seats
  const seatsFilled =
    poolDirection === 'pickup'
      ? pickupSeatsFilled
      : poolDirection === 'dropoff'
        ? dropoffSeatsFilled
        : (dropoffSeatsFilled ?? pickupSeatsFilled)
  const isSeatsFilled: boolean = seatsFilled != null && seatsFilled > 0
  const seatsAvailable =
    poolDirection === 'pickup'
      ? pickupSeatsAvailable
      : poolDirection === 'dropoff'
        ? dropoffSeatsAvailable
        : (dropoffSeatsAvailable ?? pickupSeatsAvailable)
  const isSeatsAvailable: boolean = seatsAvailable != null && seatsAvailable > 0
  // Use school & student schedule to find school end time
  const schoolEndTime =
    schoolScheduleGroups?.find((group: ScheduleGroupProps) => group?.schoolScheduleGroupName === scheduleGroupName)
      ?.schoolScheduleGroupEndTime ?? 0
  // Use school end time, custom student drop off times & default student settings to derive calendar events
  const events: CalendarEventProps[] = toCalendarEvents(schoolEndTime, dropoffTimes ?? {}, dropoffTimeDefault)
  // Need pool date to show a time
  let time: string = ''
  if (poolDate) {
    const dropoffTime = dropoffTimes && dropoffTimes[poolDate] ? dropoffTimes[poolDate] : undefined
    time = getTimeString(getFinalTime(schoolEndTime, dropoffTime, dropoffTimeDefault))
  }
  // New line characters in comments are converted to <br /> elements
  const formattedPickupComments = pickupComments.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))
  const formattedDropoffComments = dropoffComments.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))

  // A shared morning or afternoon carpool request is a pre-condition to seeing any family user
  // As such, a distance is
  if (distance === undefined) return <LinearProgress />
  const distanceMiles: string = Math.round(distance * METERS_TO_MILES * 10) / 10 + ' miles'

  const body: JSX.Element = (
    <div className={`flex flex-col flex-1 self-stretch gap-2 ${isWeb ? '' : 'px-5 mb-[120px]'}`}>
      {/* **************** Summary Profile & Pool Schedule **************** */}
      <div className={`flex flex-col items-start gap-3 my-4 self-stretch lg:p-2 lg:border lg:rounded-xl`}>
        {/* **************** User Name, Relationship, Social Links **************** */}
        <div className='flex justify-between items-center self-stretch'>
          {/****** Left Element ******/}
          <div className='flex items-center gap-2 flex-1'>
            <AvatarComponent size={12} name={userName} photoLink={photoLink}></AvatarComponent>
            <div className='flex flex-col'>
              <div className='flex items-center gap-1'>
                <span className='text-[#474747] text-sm font-medium truncate max-w-[100px] md:max-w-[200px] lg:max-w-[300px] overflow-hidden'>
                  {userName}
                </span>
                <span className='w-[1px] bg-gray-300 h-[20px]' />
                <span className='text-[#797979] text-xs font-medium'>{userRelationship}</span>
              </div>
              <span className='text-[#FF7900] text-sm font-normal'>{distanceMiles} away</span>
            </div>
          </div>
          {/****** Right Element ******/}
          <div className='flex items-end gap-0'>
            {facebookLink && (
              <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
                <img src={facebook} alt='Facebook' className='w-[40px] h-[40px]' />
              </a>
            )}
            {instagramLink && (
              <a href={instagramLink} target='_blank' rel='noopener noreferrer'>
                <img src={instagram} alt='Facebook' className='w-[40px] h-[40px]' />
              </a>
            )}
            {linkedinLink && (
              <a href={linkedinLink} target='_blank' rel='noopener noreferrer'>
                <img src={linkedin} alt='LinkedIn' className='w-[40px] h-[40px]' />
              </a>
            )}
          </div>
        </div>
        {/* **************** Pickup Time, Schedule, Seats **************** */}
        <div className='flex items-center gap-2'>
          {time && (
            <div className='flex items-center gap-1'>
              <TimeIcon />
              <span className='text-headings text-sm font-normal'>{time}</span>
            </div>
          )}
          {time && scheduleGroupName && <LineIcon />}
          {scheduleGroupName && (
            <>
              <ScheduleGroupIcon />
              <span className='text-headings text-sm font-normal'>{scheduleGroupName}</span>
            </>
          )}
          {(time || scheduleGroupName) && isSeatsAvailable && <LineIcon />}
          {(isSeatsFilled || isSeatsAvailable) && (
            <>
              <SeatIcon />
              {isSeatsFilled && <span className='text-headings text-sm font-normal'>{seatsFilled} filled</span>}
              {isSeatsAvailable && (
                <span className='text-headings text-sm font-normal'>{seatsAvailable} available</span>
              )}
            </>
          )}
        </div>
      </div>

      {/* **************** User Bio **************** */}
      <div className='py-3'>
        <div className='flex items-center gap-2 flex-1'>
          <UserBioIcon />
          <div>
            <span>Get to Know This Family</span>
          </div>
        </div>
        <div className='text-sm pt-2 text-disable'>
          <TruncatedText text={userBio || "This family hasn't added their details yet."} />
        </div>
      </div>

      {/* **************** Activities & Schedule **************** */}
      <div className='py-3'>
        <div className='flex items-center gap-2 flex-1'>
          <ScheduleIcon />
          <div>
            <span>After-School Schedule</span>
            <div className='text-sm text-disable'>Default school pick up time is {getTimeString(schoolEndTime)}</div>
          </div>
        </div>
        <div className='pt-2' data-testid='row-testid'>
          {Array.isArray(activities) && activities.length > 0 && (
            <div className={`flex flex-wrap items-center gap-1 `}>
              <div className='text-sm'>Activities:</div>
              {activities.map((activity: any, index: number) => (
                <div key={index} className='flex items-center gap-1'>
                  <div className='text-sub-headings font-normal text-sm'>{activity}</div>
                  {index < activities.length - 1 && (
                    <div style={{ background: '#666', width: '5px', height: '5px', borderRadius: '50%' }}></div>
                  )}
                </div>
              ))}
            </div>
          )}
          <CalendarComponent events={events} />
        </div>
      </div>

      {/* **************** Comments **************** */}
      {/* Calendar has ample bottom margin, so disable vertical margin */}
      {(pickupComments || dropoffComments) && (
        <div className='py-0'>
          <div className='flex items-center gap-2 flex-1'>
            <CommentIcon />
            <div>
              <span>Carpooling Comments</span>
            </div>
          </div>
          <div className='pt-2' data-testid='row-testid'>
            {pickupComments && (
              <div>
                <span className='text-sm'>Morning: </span>
                <span className='text-sm text-disable'>{formattedPickupComments}</span>
              </div>
            )}
            {dropoffComments && (
              <div>
                <span className='text-sm'>Afternoon: </span>
                <span className='text-sm text-disable'>{formattedDropoffComments}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
  const footer: JSX.Element = (
    <>
      {true ? (
        <ButtonMobileComponent
          text='Message now'
          color='bg-custom-yellow'
          width='w-full'
          onClick={() => handleMessage(roomId)}
          iconPosition='left'
          icon={<MessagingDotIcon />}
        />
      ) : (
        <div className='flex flex-grow mt-2 items-center gap-2 justify-center text-gray-400 bg-light-yellow border border-custom-yellow py-2 rounded-lg'>
          <Tooltip title={tooltipText} arrow>
            <div onClick={handleCopy}>
              <CopyIcon />
            </div>
          </Tooltip>
          {contact}
        </div>
      )}
    </>
  )

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Mobile *****************/}
      {!isWeb && (
        <BackComponent text={userName}>
          {body}
          <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-3'>
            <div className='border-b border-gray-200'></div>
            <div className='pt-5 pb-10 px-5'>{footer}</div>
          </div>
        </BackComponent>
      )}
      {/***************** Web *****************/}
      {isWeb && (
        <WizardComponent title={userName}>
          <>
            {body}
            {footer}
          </>
        </WizardComponent>
      )}
    </>
  )
}

export default Index
