import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import InputMobileComponent from 'components/input-mobile'
import NavComponent from 'components/nav'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { parsePhoneNumber } from 'libphonenumber-js/max'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { baseURL, passwordTooltip, reenterPasswordTooltip } from 'utils/constants'
import { MessageProps } from 'utils/types'
import { setUserPassword } from 'utils/users'
import { isMatchingPassword, isValidPassword, isValidPhone } from 'utils/validations'
import './index.css'

function Index(): JSX.Element {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const pn: string = params?.pn ?? ''
  const tp: string = searchParams.get('tp') ?? ''
  const navigate: NavigateFunction = useNavigate()

  // user states
  const [vars, setVars] = useState<any>({
    userPassword: '',
    userReenteredPassword: '',
    isConsent: false,
  })
  const [msg, setMsg] = useState<MessageProps>()
  const { userPassword, userReenteredPassword, isConsent } = vars

  // hooks
  useEffect(() => {
    (function () {
      if (!isValidPhone(pn, true) || !isValidPassword(tp, true)) navigate('/')
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handlers
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    const { msg, error } = await setUserPassword(pn, tp, userPassword)
    if (error) setMsg(msg)
    else {
      navigate(`/signin`)
    }
  }

  const isValid: boolean =
    isValidPassword(userPassword, true) && isMatchingPassword(userReenteredPassword, userPassword, true) && isConsent

  return (
    <WizardComponent
      onBack={onBack}
      title={'Activate Family Account'}
      subtitle={`Your Carpool.School Username: ${parsePhoneNumber(pn ?? '').formatNational()}`}
      disable={!isValid}
      btnText={'Next'}
      onClick={() => onNext()}
      data_testId={'next-btn-testid'}
    >
      <Box display='flex' flexDirection='column' justifyContent='center' paddingTop={2} gap={2}>
        <InputMobileComponent
          type='Password'
          labeltext='Create Password'
          data_testid='create-password-testid'
          isRequired={true}
          isError={!isValidPassword(userPassword)}
          helperText={!isValidPassword(userPassword) ? passwordTooltip() : ''}
          onChange={(v: string) => setVars({ ...vars, userPassword: v })}
        />
        <InputMobileComponent
          type='Password'
          labeltext='Confirm Password'
          data_testid='confirm-password-testid'
          isRequired={true}
          isError={!isMatchingPassword(userReenteredPassword, userPassword)}
          helperText={!isMatchingPassword(userReenteredPassword, userPassword) ? reenterPasswordTooltip() : ''}
          onChange={(v: string) => setVars({ ...vars, userReenteredPassword: v })}
        />
        <Box display='flex' flexDirection='row' justifyContent='center' paddingTop={2}>
          <Checkbox
            size='small'
            color='warning' // Update checkbox state
            checked={isConsent}
            onChange={(v: any) => setVars({ ...vars, isConsent: v.target.checked })}
          />
          <div className='text-gray-400 text-sm'>
            I agree to the Carpool School Inc.{' '}
            <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
              <NavComponent
                href={`${baseURL}/home/index.html#eula`}
                text='End User License Agreement'
                isOpenNewTab={true}
              />
            </span>
            . Carpool.School does not verify drivers{"'"} licenses, insurance, or conduct background checks. Users are
            responsible for screening all participants.
          </div>
        </Box>
      </Box>
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </WizardComponent>
  )
}

export default Index
