import { Box, LinearProgress } from '@mui/material'
import BackComponent from 'components/back'
import ButtonMobileComponent from 'components/button-mobile'
import InputMobileComponent from 'components/input-mobile'
import ToastComponent from 'components/toast'
import React, { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { parseJwt, passwordTooltip, reenterPasswordTooltip } from 'utils/constants'
import { MessageProps, StudentProps } from 'utils/types'
import { signInUser, updateUserPassword } from 'utils/users'
import { isMatchingPassword, isValidPassword } from 'utils/validations'
import Arrow from '../../images/arrow_forward.png'
import './index.css'

interface Props {
  tokens: any
  students: StudentProps[]
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Tokens, Students, User Relation, and Callback
  const { tokens } = props ?? {}
  const { AccessToken: accessToken, IdToken } = tokens
  const phoneNumber: string = parseJwt(IdToken).phone_number

  // states
  const [password, setPassword] = useState({ current: '', new: '', reenteredNew: '' })
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = React.useState<string>('')

  // handlers
  // Cancel button handler
  const handleCancel = async () => navigate(`/`)
  // Save button handler
  const handleSave = async () => {
    setIsLoading('Updating user password...')
    const { msg, error } = await updateUserPassword(phoneNumber, accessToken, password.current, password.new)
    if (error) setMsg(msg)
    else {
      const { msg, error } = await signInUser(phoneNumber, password.new)
      if (error) setMsg(msg)
      else {
        setPassword({ current: '', new: '', reenteredNew: '' })
        setMsg({ style: 'success', text: 'Password updated successfully.' })
      }
    }
    setIsLoading('')
  }

  const isPasswordValid: boolean =
    isValidPassword(password.current, true) &&
    isValidPassword(password.new, true) &&
    isMatchingPassword(password.reenteredNew, password.new, true)

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Display back *****************/}
      <BackComponent text='Change Password' onClick={handleCancel} />
      {/***************** Change Password *****************/}
      <div className='px-5'>
        <Box display='flex' flexDirection='column' justifyContent='center' paddingTop={2} gap={2}>
          <InputMobileComponent
            type='Password'
            data_testid='current-password-id'
            labeltext='Current Password'
            isError={!isValidPassword(password.current)}
            onChange={(v: string) => setPassword({ ...password, current: v })}
          />
          <InputMobileComponent
            type='Password'
            data_testid='new-password-id'
            labeltext='New Password'
            isError={!isValidPassword(password.new)}
            helperText={!isValidPassword(password.new) ? passwordTooltip() : ''}
            onChange={(v: string) => setPassword({ ...password, new: v })}
          />
          <InputMobileComponent
            type='Password'
            data_testid='reenter-password-id'
            labeltext='Confirm New Password'
            isError={!isMatchingPassword(password.reenteredNew, password.new)}
            helperText={!isMatchingPassword(password.reenteredNew, password.new) ? reenterPasswordTooltip() : ''}
            onChange={(v: string) => setPassword({ ...password, reenteredNew: v })}
          />
        </Box>
        <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-3'>
          <div className='border-b border-gray-200'></div>
          <div className='pt-4 pb-7 px-5'>
            <div className='flex justify-around gap-3'>
              <ButtonMobileComponent text='Cancel' onClick={handleCancel} color={'bg-btn-grey'} />
              <ButtonMobileComponent
                text={'Save'}
                iconPosition='right'
                icon={Arrow}
                onClick={handleSave}
                disabled={!isPasswordValid}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
