import MenuItem from '@mui/material/MenuItem/MenuItem'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import './index.css'

const SelectComponent = (props: any): JSX.Element => {
  const {
    isRequired,
    isDisabled,
    label,
    tooltip,
    options,
    defaultValue,
    value,
    onChange,
    isError,
    helperText,
    data_testid,
  } = props ?? {}
  const children: JSX.Element = (
    <TextField
      select
      required={isRequired}
      disabled={isDisabled}
      label={label}
      defaultValue={defaultValue}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      error={isError}
      helperText={helperText}
      fullWidth={true}
      color={isError ? 'error' : 'primary'}
      margin='dense'
      size='small'
      data-testid={data_testid}
      sx={{ textAlign: 'left', px: 0, '& .MuiOutlinedInput-root': { borderRadius: '30px' } }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              borderRadius: '25px',
            },
          },
        },
      }}
    >
      {options.map((v: string) => (
        <MenuItem key={v} value={v}>
          {v}
        </MenuItem>
      ))}
    </TextField>
  )
  return tooltip ? (
    <Tooltip title={tooltip} followCursor disableHoverListener>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  )
}

export default SelectComponent
