import VerificationCodeInput from 'components/VerificationCodeInput'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { getSessionItem, removeSessionItem } from 'utils/constants'
import { MessageProps } from 'utils/types'
import { resendCode, signInUser, verifyUser } from 'utils/users'
import { isValidCode } from 'utils/validations'
import './index.css'

interface Props {
  onSignUp: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: User name, password and callback
  const { onSignUp } = props ?? {}
  const e164PhoneNumber: string = getSessionItem('pn') || ''
  const userPassword: string = getSessionItem('pwd') || ''

  // states
  const [userCode, setUserCode] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()
  const [isResendEnabled, setIsResendEnabled] = useState<boolean>(false)
  const [timer, setTimer] = useState<number>(30)

  // handlers
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    // Verify User
    const { msg, error } = await verifyUser(e164PhoneNumber, userCode)
    if (error) setMsg(msg)
    else {
      // SignIn User
      const { msg, error } = await signInUser(e164PhoneNumber, userPassword)
      if (error) setMsg(msg)
      else {
        // Clean up all saved items from create user workflow
        removeSessionItem('pn')
        removeSessionItem('pwd')
        // Return
        // Optimisitic UI for sign in. We trigger the callback to retrieve data from backend and go to next page without waiting for completion.
        // Besides it being a good practice, it helps us work around a problem/bug becuase not doing so forces the UI to render this page again.
        // FIXME: We need to understand why the parent component re-renders this child component on handleStudentUpdate
        onSignUp()
        navigate('/add/school')
      }
    }
  }
  // resend verification code
  const onResendCode = async () => {
    setIsResendEnabled(false)
    setTimer(30)
    const { msg, error } = await resendCode(e164PhoneNumber)
    if (error) setMsg(msg)
    else setMsg({ style: 'success', text: 'A new code has been sent to your phone.' })
  }

  // timer
  useEffect(() => {
    let interval: any
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else if (timer === 0) {
      setIsResendEnabled(true)
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [timer])

  return (
    <WizardComponent
      onBack={onBack}
      title={'Verify Your Mobile Number'}
      disable={!isValidCode(userCode)}
      btnText={'Next'}
      onClick={onNext}
      data_testId={'next-btn-testid'}
    >
      <VerificationCodeInput onChange={(code: string) => setUserCode(code)} />
      <div className='text-gray-400 text-sm flex justify-center mt-6'>
        {isResendEnabled ? (
          <div onClick={onResendCode} className='underline underline-offset-2 cursor-pointer'>
            Resend Code
          </div>
        ) : (
          <TextComponent size='body2'>You can request another code in {timer}s</TextComponent>
        )}
      </div>

      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </WizardComponent>
  )
}

export default Index
