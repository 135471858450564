import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import './index.css'
import Chip from '@mui/material/Chip'

const TagComponent = (props: any) => {
  const { isDisabled, allowMultiple, allowNew, label, options, selected, placeholder, helperText, onChange } =
    props ?? {}

  return (
    <Autocomplete
      disabled={isDisabled}
      multiple={allowMultiple}
      freeSolo={allowNew}
      options={options.map((option: string) => option)}
      getOptionLabel={(option) => option}
      value={selected}
      filterSelectedOptions
      disableClearable
      onChange={(event: any, values: string[]) => {
        onChange(values)
      }}
      data-testid='school-activity-tag'
      renderInput={(params) => {
        return (
          <TextField {...params} variant='outlined' label={label} placeholder={placeholder} helperText={helperText} />
        )
      }}
      // FIXME: Upgrade MUI: https://github.com/mui/material-ui/issues/39833
      // Workardound for now to avoid "key" property in "...params" error on console
      // https://stackoverflow.com/questions/75818761/material-ui-autocomplete-warning-a-props-object-containing-a-key-prop-is-be
      renderOption={(props, option) => {
        return (
          <li {...props} key={option}>
            {option}
          </li>
        )
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={option} label={option} />)
      }}
    />
  )
}

export default TagComponent
