import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { PieChart } from '@mui/x-charts/PieChart'
import './index.css'

const PieChartMobileComponent = (props: any) => {
  const { title, data, height } = props
  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
      <Card sx={{ borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', m: 1.2 }}>
        <CardContent>
          <Typography color='text.secondary'>{title}</Typography>
          <center>
            <PieChart
              series={[
                {
                  innerRadius: 15,
                  paddingAngle: 1,
                  cornerRadius: 5,

                  data: data.map((item: any, index: number) => ({
                    ...item,
                    color: ['#f0e977', '#f6f2ad', '#c0ba5f', '#908c47', '#605d30'][index % 5], // Assign colors dynamically to each slice
                  })),
                },
              ]}
              width={350}
              height={height}
              slotProps={{
                legend: {
                  direction: 'row',
                  position: { vertical: 'bottom', horizontal: 'left' }, // Built-in positioning options
                  itemMarkWidth: 10,
                  itemMarkHeight: 5,
                  labelStyle: { fontSize: '13px' },
                },
              }}
              sx={{
                padding: '25px',
                transform: 'translate(-20px, -50px)', // Custom positioning for the legend if needed
                '& .MuiChartsLegend-root': {
                  transform: 'translate(-10px, 20px)', // Custom positioning for the legend if needed
                  fontSize: '10px',
                },
              }}
            />
          </center>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default PieChartMobileComponent
